import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { useEffect, useState } from 'react'
import { AppStore, T } from 'utils'
import ConditionalOption from './ConditionalOption'
import { Option, SelectedOpt, SideBarOptions } from './GeneralComponentsTypes'
import OptionBox from './OptionBox'
import { useLocation, useNavigate } from 'react-router-dom'
import { useCreatePostStore } from 'stores/CreatePostStore'

interface Props {
  options: SideBarOptions
  subOptionSelected: number | undefined
  conditionalClicked: number | undefined
  whatPage: string
  setIsConditionalIsClicked?: () => void
}

const SideBar = ({ options, subOptionSelected, conditionalClicked, whatPage, setIsConditionalIsClicked }: Props) => {
  /* --------------------------------------------- CONDITION */
  // condizione per verificare se è stato cliccato un elemento del menu dei conditional options
  const conditionIndexNormalOptions = conditionalClicked && conditionalClicked >= 0

  /* --------------------------------------------- ROUTER */
  const navigate = useNavigate()

  /* --------------------------------------------- STATE */
  const [selected, setSelected] = useState<any[]>([])

  const [conditionalOptionsSelected, setConditionalOptionsSelected] = useState<SelectedOpt[]>([])

  /* --------------------------------------------- METHODS */
  const isActive = (optIndex: number, childIndex: number) => {
    return selected[optIndex]?.selectedIndex === childIndex ? 'active' : ''
  }

  const isActiveConditional = (optIndex: number, childIndex: number) => {
    try {
      const active = selected[1].option === conditionalOptionsSelected[0].option[0].options ? 'active' : ''
      return active
    } catch (e) {
      return ''
    }
  }

  const onClickUpperOption = (opt: Option) => {
    let control = ''
    // if function is defined
    if (opt.onSelect.function) control = opt.onSelect.function()

    // if notice is defined
    opt.onSelect.notice && opt.onSelect.notice()

    if (control === 'error') return
    const timeout = opt.onSelect.timeout ?? 0

    // if navigate is defined
    opt.onSelect.navigate && setTimeout(() => navigate(opt.onSelect.navigate!, { state: opt.onSelect.state }), timeout)
  }

  // ----- @doc
  const positionY = (obj, inx) => {
    if (inx === 0)
      try {
        if (!obj.option[0]?.position) return 0
        return obj.option[0]?.position(inx)
      } catch (e) {
        return 0
      }
  }

  /* --------------------------------------------- USE EFFECT */
  useEffect(() => {
    if (conditionalClicked && options?.conditionalOptions?.[0]?.options) {
      setSelected(
        conditionalClicked
          ? [
              {
                selectedIndex: !conditionIndexNormalOptions ? options.default : 0,
                option: options.options,
              },
              {
                selectedIndex: conditionalClicked ?? 0,
                option:
                  whatPage === 'post'
                    ? [...options.conditionalOptions?.[0].options] ?? []
                    : whatPage === 'analytics'
                    ? options.conditionalOptions?.[0].options
                    : options.conditionalOptions,
              },
            ]
          : [
              {
                selectedIndex: options.default,
                option: options.options,
              },
            ]
      )
      return
    } else {
      setSelected(
        options.options[options.default].options !== undefined
          ? [
              {
                selectedIndex: !conditionIndexNormalOptions ? options.default : 0,
                option: options.options,
              },
              {
                selectedIndex: subOptionSelected,
                option:
                  whatPage === 'analytics'
                    ? options.conditionalOptions?.[0].options
                    : options.options[options.default].options,
              },
            ]
          : [
              {
                selectedIndex: options.default,
                option: options.options,
              },
            ]
      )
    }

    setConditionalOptionsSelected([
      {
        selectedIndex: conditionalClicked ?? 0,
        option: options.conditionalOptions ?? [],
      },
    ])
  }, [options, subOptionSelected, conditionalClicked])

  return (
    <SideBarContainer style={{ y: -(96 * options.options.length + 32) / 2 }}>
      {/* ------------------- UPPER OPTION ( dashboard [ primary button always visible ] ) ------------------- */}
      {options.upperOptions &&
        options.upperOptions.map((opt, i) => (
          <UpperOption onClick={() => onClickUpperOption(opt)}>
            <InnerContainer>
              {opt.icon}
              <Text>{opt.title}</Text>
            </InnerContainer>
          </UpperOption>
        ))}

      {/* ------------------- NORMAL OPTIONS ( col-0 = colonna principale /// col-1 colonna che si apre sulla sinistra della col-0) ------------------- */}
      {options.options !== undefined &&
        selected.map((el, i) => {
          const classCol = i === 0 ? 'col-0' : 'col-1'

          const condition = el.option[0].title === T.postPage.saveDraft
          const positionXConditionalOpt = condition || i === 0 ? 0 : 10

          return (
            <Options
              className={`${classCol} ${conditionalClicked ? 'condition-option-clicked' : ''}`}
              style={{ y: positionY(el, i) }}
            >
              <OptionContainer>
                <OptionBox optEl={el.option} selected={selected} setSelected={setSelected} i={i} isActive={isActive} />

                {/* ------------------- CONDITIONAL OPTIONS ( Opzioni speciali ) ------------------- */}
                {(condition || i === 0) && options.conditionalOptions && (
                  <Options
                    style={{
                      zIndex: -1,
                      bottom: 0,
                      backgroundColor: 'transparent',
                      transform: `translateX(${positionXConditionalOpt}px)`,
                    }}
                  >
                    <ConditionalOption
                      element={[options.conditionalOptions![i]]}
                      selected={[{ selectedIndex: conditionalClicked ?? 0, option: [options.conditionalOptions![0]] }]}
                      setSelected={setConditionalOptionsSelected}
                      elIndex={0}
                      isActive={isActiveConditional}
                      innerSelected={selected}
                      setInnerSelected={setSelected}
                      innerIndex={i}
                      innerE={el}
                      whatPage={whatPage}
                      setIsConditionalIsClicked={setIsConditionalIsClicked}
                    />
                  </Options>
                )}
              </OptionContainer>
            </Options>
          )
        })}
    </SideBarContainer>
  )
}

export default SideBar

const SideBarContainer = styled(Box)`
  gap: 16px;
  position: relative;
  min-width: 100px;
  box-shadow: none;
  margin-right: 8px;
`

const UpperOption = styled(Box)`
  top: -110px;
  right: -5px;
  z-index: 999;
  position: absolute;
  min-width: 96px;
  height: auto;
  background-color: ${() => AppStore.theme.o.surface};
  display: flex;
  padding: 8px;
  gap: 16px;
  flex-direction: column;
  box-shadow: ${() => AppStore.theme.mainShadow};
  transform: translateX(-10px);

  &:hover {
    background-color: ${() => AppStore.theme.o.surface};
  }
`
const Options = styled(Box)`
  position: absolute;
  height: auto;
  background-color: ${() => AppStore.theme.o.surface};
  display: flex;
  padding: 8px;
  gap: 16px;
  flex-direction: column;
  box-shadow: ${() => AppStore.theme.mainShadow};
  transform: translateX(-10px);

  &.col-1 {
    animation: col-1-animation 0.5s ease-in-out;
    transform: translate(-120px, 0px);

    &.condition-option-clicked {
      animation: col-1-animation-conditional 0.5s ease-in-out;
      transform: translate(-120px, 288px);
    }
  }

  @keyframes col-1-animation {
    from {
      opacity: 0;
      transform: translate(-120px, 20px);
    }
    to {
      opacity: 1;
      transform: translate(-120px, 0px);
    }
  }

  @keyframes col-1-animation-conditional {
    from {
      opacity: 0;
      transform: translate(-120px, 270px);
    }
    to {
      opacity: 1;
      transform: translate(-120px, 290px);
    }
  }
`

const OptionContainer = styled(Box)`
  position: relative;
  gap: 16px;
`

const InnerContainer = styled(Box)`
  min-width: 80px;
  min-height: 80px;
  background-color: ${() => AppStore.theme.o.surface};
  /* center */
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 36px;
    height: 36px;
    path {
      fill: ${() => AppStore.theme.o.darkGrey};
    }
  }

  &:hover {
    background-color: ${() => AppStore.theme.o.lightBlue};
    svg {
      path {
        fill: ${() => `${AppStore.theme.o.blue}`};
      }
    }
    text {
      color: ${() => `${AppStore.theme.o.blue}`};
    }
  }

  &.active {
    background-color: ${() => AppStore.theme.o.lightBlue};
    svg {
      path {
        fill: ${() => `${AppStore.theme.o.blue}`};
      }
    }
    text {
      color: ${() => `${AppStore.theme.o.blue}`};
    }
  }

  &.special {
    width: 9px;
  }
`

const Text = styled.text`
  font-size: 12px;
  font-style: normal;
  color: ${() => AppStore.theme.o.darkGrey};
  font-weight: 400;
`
