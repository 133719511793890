/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { openEmojidModal } from 'components/Modal/ModalManager'
import { useEffect } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { AppStore, T } from 'utils'
import { getActiveDescription, getActiveText, updateActive } from 'utils/PostUtils/Post'
import {
  canShowMetadata,
  getLinkProperties,
  manageGlobalLinkUpdate,
  manageVariantLinkUpdate,
  onPasteLink,
} from 'utils/PostUtils/ShortUrl'
import { Metadata } from './Metadata/Metadata'
import { TextPostInfo } from './TextInfo/TextPostInfo'
import { Utm } from './Utm/Utm'

/* ------------------- INTERFACES  */
interface Props {}

export const TextContainer = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost, posts } = useCreatePostStore((s) => ({
    activePost: s.activePost as any,
    posts: s.posts,
  }))

  /* ----------------- VARIBLES  */
  const showMetadata = canShowMetadata()

  const showUtm = getLinkProperties()!.length > 0

  const isYoutube = activePost && activePost?.info?.account?.provider === 'youtube'

  /* ----------------- METHODS  */
  // Calcola l'altezza del Textarea in base alle condizioni date
  const calculateTextareaHeight = () => {
    if (isYoutube) {
      return '55px'
    } else {
      let height = 604 // su figma le preview hanno altezze diverse, questa è messa perchè sembra la più bilanciata a livello di padding

      if (showUtm && utmProviderCheck()) {
        height -= 56
      }

      if (showMetadata && utmProviderCheck()) {
        height -= 160
      }

      return `${height}px`
    }
  }

  const calculateTextareaHeightDescriptionYT = () => {
    let height = 545

    if (showUtm && utmProviderCheck()) {
      height -= 56
    }

    return `${height}px`
  }

  const utmProviderCheck = () => {
    const provider = activePost && activePost?.info?.account?.provider
    const bannedProviders = ['instagram', 'tiktok']
    return !bannedProviders.includes(provider)
  }

  const linkCheck = () => {
    return activePost?.data?.urls?.length > 0 || activePost?.urls?.length > 0
  }

  const onTextAreaChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value
    // ottiene il testo correntemente in modifica da parte dell'utente ( se è globale o meno )
    const activeText = getActiveText()
    updateActive({ text: e.target.value })

    /*
    // Se la lunghezza del testo è maggiore di 1, vuol dire che è stato incollato un link
    if (description.length > activeText.length + 1) onPasteLink({ activePost, newText: description })
      */
    //if (description.length > activeText.length + 1) onPasteLink({ activePost, newText: description })
    const dataUrls = activePost.urls

    await verifyUrls(activeText, description)
  }

  const verifyUrls = async (activeText: string, description: string): Promise<void> => {
    // extract urls from the text wit all the parameters
    const urlRegex =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g

    // Urls in the active text
    const contentUrls: string[] = description?.match(urlRegex) ?? []

    // Check if users has social accounts with ref parameters in his workspace
    if (!AppStore.shortenAvailable) {
      const { socialsAdded, globalPost } = useCreatePostStore.getState()

      // If this parameter is present user is modifying a single post
      if (activePost?.data) {
        await manageVariantLinkUpdate(contentUrls, activePost, urlRegex, description)
        return
      } else if (socialsAdded.length > 0) {
        //Global post with social accounts added
        const updatedPosts = await manageGlobalLinkUpdate(contentUrls, posts, urlRegex)
        // Update the posts in the store
        useCreatePostStore.setState({ posts: updatedPosts })
        return
      }

      // If user is editing a global post and has no social accounts added in section return and wait some user action
      return
    } else {
      // if user has no ref parameters in his workspace then do the classic shortening logic
      onPasteLink({ activePost, newText: description })
    }
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    verifyUrls(getActiveText(), !!activePost?.data ? activePost?.data?.text : activePost?.text)
  }, [activePost?.info, posts.length])

  return (
    <TextContainerContainer>
      {/* Textarea  generale */}
      <TextareaContainer
        style={{
          height: calculateTextareaHeight(),
        }}
      >
        {/* Titolo del post */}
        <TextAreaPost
          id="text-area-create-post"
          value={getActiveText()}
          onChange={onTextAreaChange}
          placeholder={isYoutube ? T.postPage.insertHereTextYouTube : T.postPage.insertHereText}
        />

        {!isYoutube && <EmojiIcon onClick={() => openEmojidModal('text-area-create-post')} />}

        {/* Commentato "Ricevi da globale" */}
        {/* {activePost?._id !== 'GLOBAL' && isUnique(activePost?.variantId) && <GlobalPull />} */}
      </TextareaContainer>

      {/* Textarea  descrizione youtube */}
      {isYoutube && (
        <TextareaContainer>
          <TextAreaPost
            id="text-area-create-post-description"
            style={{ height: calculateTextareaHeightDescriptionYT() }}
            value={activePost.data.description}
            placeholder={T.postPage.changeDescriptionYouTube}
            onChange={(e) => {
              const description = e.target.value
              const activeDescription = getActiveDescription() ?? ''
              updateActive({ description })
              if (description.length > activeDescription.length + 1)
                onPasteLink({ activePost, newText: description, isDescription: true })
            }}
          />
          <EmojiIcon onClick={() => openEmojidModal('text-area-create-post-description')} />
        </TextareaContainer>
      )}

      {/* Metadata */}
      {linkCheck() && showMetadata && utmProviderCheck() && <Metadata post={activePost} />}

      {/* UTM */}
      {linkCheck() && showUtm && utmProviderCheck() && <Utm post={activePost} isDescription={isYoutube} />}

      {/* Length text check, time to read, Text difficulty */}
      <TextPostInfo />
    </TextContainerContainer>
  )
}

const TextContainerContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
`

const TextareaContainer = styled(Box)`
  border: 1px solid ${() => AppStore.theme.o.grey};
  position: relative;
  border: none;
  width: 442px;
`

const EmojiIcon = styled((props) => <Icons.emojiIcons {...props} />)`
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  transition: 300ms ease-in-out;
  cursor: pointer;
  fill: ${AppStore.theme.o.darkGrey};

  :hover {
    fill: ${AppStore.theme.o.grey};
  }
`

const TextTitlePost = styled.input`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  resize: none;
  background: ${() => AppStore.theme.o.lightestGrey};
  transition: 300ms ease-in-out;
  color: ${() => AppStore.theme.o.black};
  font-weight: 600;
  border: 1px solid ${() => AppStore.theme.o.grey};
  box-sizing: border-box;

  &:hover,
  &:focus {
    background: ${() => AppStore.theme.o.lightGrey};
  }

  &::placeholder {
    font-weight: 600;
    color: ${() => AppStore.theme.o.darkGrey};
  }
`

const TextAreaPost = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  scrollbar-width: none;
  resize: none;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
  transition: 300ms ease-in-out;
  color: ${() => AppStore.theme.o.black};
  font-weight: 600;

  &:hover,
  &:focus {
    background: ${() => AppStore.theme.o.lightGrey};
  }

  &::placeholder {
    font-weight: 600;
    color: ${() => AppStore.theme.o.darkGrey};
  }
`
