/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Pagination from '@mui/material/Pagination'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import {
  IDateFilter,
  ILabelsFilter,
  IOrderFilter,
  IPostFilters,
  TPostMediaType,
  TPostOrderDirection,
  TPostOrderKey,
  TPostType,
} from 'api/Reports'
import { Box, Icons } from 'components'
import { EmptyState } from 'components/UI'
import { useCallback, useState } from 'react'
import { useReportStore } from 'stores/ReportStore'
import { AppStore, T } from 'utils'
import { GraphPagination } from '../../Graph'
import { MAX_TABLE_PAGE_SIZE, TableBuilder } from './TableGraph.utils'
import { TableHeader } from './TableHeader'
import { TablePost } from './tableInterface'
import InfiniteLoader from 'pages/ai-discover/UI/InfiniteLoader'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  data: TablePost[]
  identifier?: string
  provider: string
  onDataRefresh?: (filters?: IPostFilters, page?: number) => Promise<void>
  pagination?: GraphPagination
  error?: any
}
// ------------------- COMPONENT  -------------------- */

export const TableGraph = ({ data, provider, onDataRefresh, pagination, identifier, error }: Props) => {
  /* ----------------- VARIABLES  */
  const rows = data.map(TableBuilder.buildRow)
  const columns: GridColDef[] = TableBuilder.getColumns(provider) ?? []

  // pagination
  const page = Number(pagination?.page) || 1
  const isPaginated = pagination && pagination.totalPages && pagination.totalPages > 1 ? true : false

  /* ----------------- STATE  */
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false)
  const apiCallParams = useReportStore.getState().getMetric(identifier!)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- API CALL  ------------ */
  const fetchRows = useCallback(async (filters?: IPostFilters, newPage?: number) => {
    try {
      setIsLoading(true)
      await onDataRefresh?.(filters, newPage)
      setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }, [])

  /**
   * On page changed
   * @param page
   */
  const updatePage = useCallback(async (newPage: number) => {
    await fetchRows?.(apiCallParams, newPage)
  }, [])

  /**
   * Funzione per aggiornare i valori dell'apiCallParams da un componente figlio
   * @param value Può essere qualunque key dell'apiCallParams, quindi labels, format, ect..
   */
  const updateValues = async (value: ILabelsFilter | TPostMediaType[] | TPostType[] | IDateFilter | IOrderFilter) => {
    const newApiCall = { ...apiCallParams, ...value }

    useReportStore.getState().setMetric(newApiCall, identifier!)
    await fetchRows?.(newApiCall, page)
  }

  /* ----------------- METHODS  */

  function replaceNewlinesWithBreaks(text) {
    return text.split('\n').map((item, index) => (
      <Box center>
        {item}
        <br />
      </Box>
    ))
  }

  /**
   * On sort changed
   * Update data in store and fetch new data
   * @param model
   * @returns
   */
  const onSortModelChange = async (model) => {
    let defaultDirection: TPostOrderDirection = model[0]?.sort != 'asc' ? 1 : -1

    const sortID = ((model[0]?.field as TPostOrderKey) !== 'date' && model[0]?.field) || 'createdAt'
    const defaultPage = 1

    if (apiCallParams?.order && apiCallParams.order.key === sortID) {
      defaultDirection = apiCallParams.order.direction == 1 ? -1 : 1
    }

    const filters: IPostFilters = {
      ...apiCallParams,
      order: {
        key: sortID,
        direction: defaultDirection,
      },
    }

    useReportStore.getState().setMetric(filters, identifier!)
    await fetchRows?.(filters, defaultPage)
  }

  // ----------------- PAGINATION  --------------- */
  const TablePagination = ({}: Props) => {
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
      updatePage(value)
    }
    return (
      <PaginationContainer>
        <Pagination count={pagination?.totalPages} page={page} onChange={handleChange} />
      </PaginationContainer>
    )
  }

  const TableLoading = () => {
    return (
      <div
        style={{
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: 1,
          width: '100%',
          zIndex: 100,
          height: '100%',
          backgroundColor: AppStore.theme.o.background,
        }}
      >
        <InfiniteLoader></InfiniteLoader>
      </div>
    )
  }

  return (
    <TableGraphContainer>
      <TableHeader apiCallParams={apiCallParams} updateValues={updateValues} provider={provider} />

      {!error && rows.length > 0 && (
        <DataGridPro
          isCellEditable={undefined}
          isRowSelectable={undefined}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableColumnResize
          disableColumnReorder
          loading={isLoading}
          rows={!isLoading ? rows : []}
          columns={columns}
          pageSize={MAX_TABLE_PAGE_SIZE}
          pagination={isPaginated}
          paginationMode="server"
          onSortModelChange={onSortModelChange}
          components={{
            LoadingOverlay: TableLoading,
            Pagination: TablePagination,
          }}
          onPageChange={updatePage}
          rowHeight={123}
          sx={{
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 1,
            },
          }}
        />
      )}

      {
        // Error
        error && (
          <EmptyState
            noPaddingTop
            icon={<Icons.sadFace fill={AppStore.theme.o.black} />}
            text={T.error.analyticsPeriodError}
          />
        )
      }

      {/* EmptyState */}
      {rows.length === 0 && !error && (
        <EmptyState
          noPaddingTop
          icon={<Icons.sadFace fill={AppStore.theme.o.black} />}
          text={replaceNewlinesWithBreaks(T.analyticsPage.tableGraphEmptyState)}
        />
      )}
    </TableGraphContainer>
  )
}

const TableGraphContainer = styled(Box)`
  width: 100%;
  height: 100%;
  gap: 16px;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px 42px 0px;
  width: 100%;
`
