import { geolocationApiCall } from 'api/Geolocation'
import {
  IGeolocationData,
  IGeolocationDataAddress,
  IPositionCoordinates,
  IUserAgentData,
  IUserData,
  MixpanelBrowser,
  MixpanelDevice,
  MixpanelGeolocation,
  MixpanelRefUrl,
  MixpanelUtm,
  TUserAgentBrands,
} from './utilsInterfaces'
import { IPostData, IPostVariant } from 'types/PostInterface'
import { SocialType } from 'api/Utils'

/**
 * The function `getUserPositionData` returns a promise that resolves with the user's current position
 * coordinates or rejects with an error if the position cannot be obtained.
 * @returns a Promise object.
 */
export function getUserPositionData(): Promise<IPositionCoordinates> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position.coords)
      },
      (error) => {
        reject(error)
      }
    )
  })
}

/**
 * The function `getUserAgentData` retrieves user agent data such as browser name, version, operating
 * system, device type, screen height, and screen width.
 * @returns an object containing the following properties:
 */
export function getUserAgentData(): IUserData {
  const userAgentData: IUserAgentData = (navigator as any).userAgentData

  const brands: TUserAgentBrands[] = userAgentData.brands
  const platform: string = userAgentData.platform

  const device: boolean = userAgentData.mobile
  let browserName
  let browserVersion

  brands.forEach((element) => {
    if (element.brand != 'Not/A)Brand' && element.brand != 'Chromium') {
      browserName = element.brand
      browserVersion = element.version
    }
  })

  const userData: IUserData = {
    browserName: browserName,
    browserVersion: browserVersion,
    os: platform,
    deviceType: device ? 'Mobile' : 'Desktop',
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    device: device,
  }

  return userData
}

/**
 * The function `getUtmProperties` returns a hardcoded object containing dummy UTM properties.
 * @returns an object of type MixpanelUtm, which contains properties for utm_source, utm_medium,
 * utm_campaign, utm_term, and utm_content. The values for these properties are currently set to
 * 'test'.
 */
export function getUtmProperties(): MixpanelUtm {
  //TODO retrieve actual utm data
  const utmProperties: MixpanelUtm = {
    //UTMs to check
    utm_source: 'test',
    utm_medium: 'test',
    utm_campaign: 'test',
    utm_term: 'test',
    utm_content: 'test',
  }

  return utmProperties
}

/**
 * The function `getBrowserProperties` returns an object containing the browser name and version based
 * on the user agent data.
 * @returns an object of type MixpanelBrowser, which contains the properties the browser name and browser version
 * .
 */
export function getBrowserProperties(): MixpanelBrowser {
  const data: IUserData = getUserAgentData()

  const browserProperties: MixpanelBrowser = {
    $browser: data.browserName,
    $browser_version: data.browserVersion,
  }

  return browserProperties
}

export function getRefUrlProperties(sanitizedUrl?: string): MixpanelRefUrl {
  const data = {
    $current_url: sanitizedUrl ? sanitizedUrl : window.location.href,

    // initial_referrer: '',
    // initial_referring_domain: '',
    // referrer: '',
    // $referring_domain: '',
    // search_engine: '',
  }

  return data
}

/**
 * The function `getDeviceProperties` returns an object containing device properties such as device
 * type, operating system, screen height, and screen width.
 * @returns an object of type MixpanelDevice.
 */
export function getDeviceProperties(): MixpanelDevice {
  const data: IUserData = getUserAgentData()

  return {
    $device: String(data.device),
    $os: data.os,
    $screen_height: data.screenHeight.toString(),
    $screen_width: data.screenWidth.toString(),
  }
}

/**
 * The function `getLocationProperties` retrieves geolocation data and returns an object with
 * properties such as city, region, and country code.
 * @returns a Promise that resolves to a MixpanelGeolocation object or undefined.
 */
export async function getLocationProperties(): Promise<MixpanelGeolocation | undefined> {
  try {
    const data: IGeolocationData = await geolocationApiCall()
    const address: IGeolocationDataAddress = data.address

    const geolocationProperties: MixpanelGeolocation = {
      $city: address.city || address.town || address.village || address.municipality || '',
      $region: address.state,
      mp_country_code: address.country_code,
    }

    return geolocationProperties
  } catch (error) {
    console.error(error)
  }
}

/**
 * The function `getMediaFileFormats` extracts file formats from media objects in a post variant.
 * @param {IPostVariant} post - IPostVariant - an interface representing a post variant.
 * @returns The function `getMediaFileFormats` returns an array of file formats extracted from the
 * `mimetype` property of media objects in the `post.data.medias` array of an `IPostVariant` object.
 */
export function getMediaFileFormats(post: IPostVariant) {
  const fileFormats: string[] = []

  if (post.data.medias && Array.isArray(post.data.medias)) {
    post.data.medias.forEach((media) => {
      if (media.mimetype) {
        const fileFormat = media.mimetype.split('/')[1]
        if (fileFormat) {
          fileFormats.push(fileFormat)
        }
      }
    })
  }

  return fileFormats
}

/**
 * This functions returns the social extras of a post based on the provider type of the post.
 * @param {IPostVariant} post The post object that contains the data.
 * @returns An Object with the social extras of the post.
 */
export function getSocialExtras(post: IPostVariant) {
  const provider: SocialType = post.info.account.provider

  const socialExtras: { [key: string]: any } = {}

  switch (provider) {
    case 'instagram':
      Object.assign(socialExtras, getInstagramExtras(post.data))
      break
    case 'facebook':
      Object.assign(socialExtras, getFacebookExtras(post.data))
      break
    case 'x':
      Object.assign(socialExtras, getXExtras(post.data))
      break
    case 'linkedin':
      Object.assign(socialExtras, getLinkedinExtras(post.data))
      break
    case 'tiktok':
      Object.assign(socialExtras, getTikTokExtras(post.data))
      break
    case 'youtube':
      Object.assign(socialExtras, getYouTubeExtras(post.data))
      break
  }

  return socialExtras
}

/**
 * This function extracts the social extras of an Instagram post.
 * @param {IPostData} data
 * @returns The social extras of the Instagram post.
 */
function getInstagramExtras(data: IPostData) {
  const properties: { [key: string]: any } = {}

  const firstComment = extractFirstComment(data)
  if (firstComment) {
    properties.firstComment = firstComment.text
  }

  const tags = extractInstagramTags(data)
  if (tags.length > 0) {
    properties.tags = tags
  }

  return properties
}

/**
 * This function extracts the social extras of a Facebook post.
 * @param {IPostData} data
 * @returns The social extras of the Facebook post.
 */
function getFacebookExtras(data: IPostData) {
  const properties: { [key: string]: any } = {}

  const firstComment = extractFirstComment(data)

  if (firstComment) {
    properties.firstComment = firstComment.text
  }

  return properties
}

/**
 * This function extracts the social extras of a X post.
 * @param {IPostData} data
 * @returns The social extras of the X post.
 */
function getXExtras(data: IPostData) {
  const properties: { [key: string]: any } = {}

  const firstComment = extractFirstComment(data)

  if (firstComment) {
    properties.firstComment = firstComment.text
  }

  return properties
}

/**
 * This function extracts the social extras of a LinkedIn post.
 * @param {IPostData} data
 * @returns The social extras of the LinkedIn post.
 */
function getLinkedinExtras(data: IPostData) {
  const properties: { [key: string]: any } = {}

  const firstComment = extractFirstComment(data)

  if (firstComment) {
    properties.firstComment = firstComment.text
  }

  return properties
}

/**
 * This function extracts the social extras of a YouTube post.
 * @param {IPostData} data
 * @returns The social extras of the YouTube post.
 */
function getYouTubeExtras(data: IPostData) {
  const properties: { [key: string]: any } = {}

  if (data.hashtags) {
    properties.hashtags = data.hashtags
  }

  if (data.category) {
    properties.category = data.category.title
  }

  if (data.privacyStatus) {
    properties.privacyStatus = data.privacyStatus
  }

  if (typeof data.embedded === 'boolean') {
    properties.embedded = data.embedded
  }

  if (typeof data.notifySubscriber === 'boolean') {
    properties.notifySubscriber = data.notifySubscriber
  }

  if (typeof data.adultOnly === 'boolean') {
    properties.adultOnly = data.adultOnly
  }

  return properties
}

/**
 * This function extracts the social extras of a TikTok post.
 * @param {IPostData} data
 * @returns The social extras of the TikTok post.
 */
function getTikTokExtras(data: IPostData) {
  const properties: { [key: string]: any } = {}

  if (typeof data.duet === 'boolean') {
    properties.duet = data.duet
  }

  if (typeof data.stitch === 'boolean') {
    properties.stitch = data.stitch
  }

  if (typeof data.commentAvaible === 'boolean') {
    properties.commentAvaible = data.commentAvaible
  }

  if (data.privacyLevel) {
    properties.privacyLevel = data.privacyLevel
  }

  return properties
}

/**
 * This function extracts the first comment from a post data object.
 * @param {IPostData} data
 * @returns The firstComment property of the post data object or null if it does not exist.
 */
function extractFirstComment(data: IPostData) {
  return data?.firstComment || null
}

/**
 * This function extracts the tags from an Instagram post data object.
 * @param {IPostData} data
 * @returns An array of tags extracted from the post data object.
 */
function extractInstagramTags(data: IPostData) {
  const tags = data?.tags || []
  return tags.map((tag) => tag.text).filter((text) => text)
}

/**
 *  This function calculates the reading time of a text in seconds.
 *  This function has the same logic as the showMinutes function in ReadingTime.tsx
 * @param {string} text
 * @returns
 */
export function calculateReadingTimeInSeconds(text: string): number {
  const numberOfWords: number = text.replace(/\s+/g, ' ').trim().split(' ').length
  if (text.length > 0) {
    if (numberOfWords === 0) {
      return 1
    }
    return Math.ceil(numberOfWords / 3.333)
  } else {
    return 0
  }
}
