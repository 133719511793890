/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Reports from 'api/Reports'
import { Box } from 'components'
import { InputEditableContent } from 'components/InputEditableContent'
import { RefreshButton } from 'components/RefreshButton'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { AnalyticsDate, IDataReport, IReportConfig } from 'types/AnalyticsInferfaces'
import { AppStore, T } from 'utils'
import { changeReportName, formatDate, getReport } from 'utils/AnalyticsUtils'
import { isVisible } from 'utils/CheckVisibleElement'
import { AddMetricOnReport } from '../Graph/singleGraph/AddMetricOnReport'
import { DownloadReportButton } from '../Graph/singleGraph/DownloadReportButton'
import { DateCompareDropdown } from '../UI/DateCompareDropdown'
import { DateRangeFilter } from '../UI/DateRangeFilter'
import { useReportStore } from 'stores/ReportStore'

/* ------------------- INTERFACES  */
interface Props {}

export const PreviewReportHeader = ({}: Props) => {
  /* ------------------- ROUTER  */
  const { reportId } = useParams()

  /* ------------------- ZUSTAND  */
  const {
    onSetReportName,
    setReportPreview,
    setSelectedDatesReports,
    setCompareDatesReports,
    isReportUpdating,
    reportUpdateDate,
    reportPreviewName,
  } = useAnalyticsStore.getState()

  const { reportPreview } = useAnalyticsStore((state) => ({
    reportPreview: state.reportPreview,
  }))

  /* ----------------- STATE  */
  const [inputReportName, setInputReportName] = useState<string>()
  const [selectedDate, setSelectedDate] = useState<AnalyticsDate | null>({
    startDate: dayjs(reportPreview?.since),
    endDate: dayjs(reportPreview?.until),
  })
  const [compareSelectedDate, setCompareSelectedDate] = useState<AnalyticsDate | null>({
    startDate: dayjs(reportPreview?.info?.comparativeRange?.from),
    endDate: dayjs(reportPreview?.info?.comparativeRange?.to),
  })

  // console.log('infocomparative', reportPreview?.info.comparativeRange)
  /* ------------------- ZUSTAND UN SUB  */
  const unSubSelectedDate = useAnalyticsStore.subscribe((state) => {
    state.selectedDatesReports !== selectedDate && setSelectedDate(state.selectedDatesReports)
  })

  const unSubSelectedCompareDate = useAnalyticsStore.subscribe((state) => {
    state.compareDatesReports !== compareSelectedDate &&
      setCompareSelectedDate(state.compareDatesEnabled ? state.compareDatesReports : null)
  })

  /* ------------------- METHOD  */
  const onChangeSelectedDateReports = (dates: AnalyticsDate | null) => {
    if (!reportPreview) return
    setSelectedDatesReports(dates)
  }

  const updateReportDate = async () => {
    if (
      reportPreview === null ||
      selectedDate === null ||
      selectedDate.endDate === null ||
      selectedDate.startDate === null
    )
      return

    setReportPreview(null)
    const reportPreviewData: IDataReport[] = []

    reportPreview.data.forEach((r: any) => {
      const existingEl = reportPreviewData?.find((d: any) => d.account === r.socialProfile._id)

      // se esiste già l'account social allora aggiungo solo la metrica
      if (existingEl) {
        existingEl?.metrics?.push(r.metricRef)
      } else {
        reportPreviewData.push({
          account: r.socialProfile._id,
          provider: r.socialProfile.provider,
          metrics: [r.metricRef],
          since: selectedDate.startDate ? selectedDate.startDate.toISOString() : reportPreview.since,
          until: selectedDate.endDate ? selectedDate.endDate.toISOString() : reportPreview.until,
        })
      }
    })

    const info = {
      ...reportPreview.info,
      comparativeRange: {
        from: compareSelectedDate?.startDate?.toISOString() ?? '',
        to: compareSelectedDate?.endDate?.toISOString() ?? '',
      },
    }

    try {
      const report: IReportConfig = await Reports.createReport({
        data: reportPreviewData,
        info: info,
        reportId: reportPreview._id,
        since: selectedDate.startDate.toISOString(),
        until: selectedDate.endDate.toISOString(),
        comparativeRange: {
          from: compareSelectedDate?.startDate?.toISOString() ?? '',
          to: compareSelectedDate?.endDate?.toISOString() ?? '',
        },
      })

      const addReport: IReportConfig = await getReport(report._id)

      onSetReportName(addReport.info.reportName!)
      setReportPreview(addReport)
    } catch (e) {
      console.error(e)
    }
  }

  const refreshReportDate = async () => {
    const startDate = dayjs(selectedDate?.startDate ?? dayjs(reportPreview?.since))
    const endDate = dayjs(selectedDate?.endDate ?? dayjs(reportPreview?.until))

    const daysDifference = endDate.diff(startDate, 'day')

    const today = dayjs()
    const newStartDate = today.subtract(daysDifference, 'day')

    setSelectedDatesReports({ startDate: newStartDate, endDate: today })

    useReportStore.getState().clearMetrics()
  }

  /* ------------------- USEEFFECT  */

  // Recupera la data di inizio e fine del report e ne deriva la data di comparazione.
  // la data di comparazione avrà sempre lo stesso range di giorni del report.
  const retriveNewComparativeRange = () => {
    // attenzione all'inversione di start-end date
    const startDate = dayjs(selectedDate?.startDate ?? dayjs(reportPreview?.since))
    const endDate = dayjs(selectedDate?.endDate ?? dayjs(reportPreview?.until))

    const daysDifference = endDate.diff(startDate, 'day')
    // data a partire dalla endDate
    const newStartDate = startDate.subtract(1, 'day')
    const newEndDate = newStartDate.subtract(daysDifference, 'day')
    return {
      startDate: newEndDate,
      endDate: newStartDate,
    }
  }

  const updateComparativeRange = () => {
    setTimeout(() => {
      const { startDate, endDate } = retriveNewComparativeRange()
      setCompareDatesReports({
        startDate: dayjs(startDate),
        endDate: dayjs(endDate),
      })
    }, 200)
  }

  useEffect(() => {
    refreshReportDate()
    unSubSelectedDate()
    unSubSelectedCompareDate()
  }, [])

  useEffect(() => {
    if (reportPreview && reportPreview.info.reportName !== inputReportName) {
      setInputReportName(reportPreview.info.reportName)
    }
  }, [reportPreview])

  useEffect(() => {
    if (reportPreviewName && reportPreviewName !== inputReportName) {
      setInputReportName(reportPreviewName)
    }
  }, [reportPreviewName])

  useEffect(() => {
    updateReportDate()
    updateComparativeRange()
  }, [selectedDate])

  useEffect(() => {
    updateReportDate()
  }, [compareSelectedDate])

  // console.log('reportPreview', reportPreview)
  // console.log('selectedDate', selectedDate)
  // console.log('compareSelectedDate', compareSelectedDate)

  return window.location.pathname.split('/')[3] && reportPreview ? (
    <HeaderDashboardAnalyticsContainer className={`header_report ${reportId === reportPreview?._id ? 'show' : ''}`}>
      {/* ---------------------- HEADER LEFT  __ last report save text*/}
      <HeaderItemContainer className="header_icon_left">
        <AddMetricOnReport />
        {isVisible('analytics.reporHeader.downloadreportBtn') && <DownloadReportButton />}
      </HeaderItemContainer>

      {/* ---------------------- HEADER CENTER __ report title*/}
      <HeaderItemContainer className="header_text_center-container">
        <InputAndDateContainer>
          <InputEditableContent
            value={inputReportName ?? ''}
            submitAction={(currentReportName) => changeReportName(reportId!, currentReportName, false)}
            defaultValue={inputReportName}
            notifyInfo={T.analytics.reportRename(1, 25)}
          />
          <LastModifyDate>
            {isReportUpdating ? (
              <>{T.info.updating}</>
            ) : (
              <>
                {T.analytics.lastSave}: {formatDate(reportUpdateDate ?? '')}
              </>
            )}
          </LastModifyDate>
        </InputAndDateContainer>
      </HeaderItemContainer>

      {/* ---------------------- HEADER RIGHT __ date picker*/}
      <HeaderItemContainer className="header_text_right">
        <RefreshButton refreshAction={refreshReportDate} />

        <DateRangeFilter
          selectedDate={selectedDate}
          setSelectedDate={onChangeSelectedDateReports}
          reportDate={[reportPreview?.since, reportPreview?.until]}
        />

        <DateCompareDropdown
          compareSelectedDate={compareSelectedDate}
          setCompareSelectedDate={setCompareDatesReports}
          selectedDateFilter={selectedDate}
        />
      </HeaderItemContainer>
    </HeaderDashboardAnalyticsContainer>
  ) : (
    <HeaderDashboardAnalyticsContainer
      className={`header_report ${reportId === reportPreview?._id ? 'show' : ''}`}
    ></HeaderDashboardAnalyticsContainer>
  )
}

const HeaderDashboardAnalyticsContainer = styled(Box)`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
  }
  &.header_report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
  }
`

const HeaderItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  &.header_icon_left {
    flex: 1;
    color: ${() => AppStore.theme.o.darkGrey};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.63px;
    letter-spacing: 1%;
    align-self: flex-end;

    justify-content: flex-start;
  }

  &.header_text_center-container {
    flex: 1;

    justify-content: center;
    align-items: center;
  }

  &.header_text_right {
    flex: 1;
    justify-content: flex-end;
  }
`

const InputAndDateContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
`

const LastModifyDate = styled.p`
  flex: 1;
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.63px;
  letter-spacing: 1%;
  align-self: center;
  justify-content: center;
  min-width: 200px;
  text-align: center;
`
