/* ------------------- IMPORTS */
import { IDataReport, IReportConfig, TSubsection } from 'types/AnalyticsInferfaces'
import { CreateReportSetup } from './CreateReportSetup'
import { CreateReportMetrics } from './CreateReportMetrics'
import { CreateReportSocials } from './CreateReportSocials'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { useEffect } from 'react'
import { getReport } from 'utils/AnalyticsUtils'
import { useNavigate, useParams } from 'react-router-dom'
import { AppStore, showInfo, T } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  subsection: TSubsection
  reportConfig: IReportConfig
  setReportConfig: (reportConfig: IReportConfig) => void
}

export const CreateReport = ({ subsection, reportConfig, setReportConfig }: Props) => {
  /* ____________________ ROUTER ____________________ */
  const { reportId } = useParams()
  const navigate = useNavigate()

  /* ____________________ ZUSTAND ____________________ */
  const { setReportPreview } = useAnalyticsStore.getState()
  const reportPreview = useAnalyticsStore((state) => state.reportPreview)

  /* ____________________ METHODS ____________________ */
  const createReportConfigFromReport = (report: IReportConfig) => {
    const reportPreviewData: IDataReport[] = []

    report.data.forEach((r: any) => {
      const existingEl = reportPreviewData?.find((d: any) => d.account === r.socialProfile._id)

      // se esiste già l'account social allora aggiungo solo la metrica
      if (existingEl) {
        existingEl?.metrics?.push(r.metricRef)
      } else {
        reportPreviewData.push({
          account: r.socialProfile._id,
          provider: r.socialProfile.provider,
          metrics: [r.metricRef],
          since: r.since,
          until: r.until,
        })
      }
    })

    setTimeout(() => setReportConfig({ data: reportPreviewData, info: report.info }), 200)
  }

  /* ____________________ API CALL ____________________ */
  const getApiReport = async () => {
    const addReport: IReportConfig = await getReport(reportId ?? window.location.pathname.split('/')[3])
    if (
      !reportPreview ||
      reportPreview._id !== reportId ||
      reportPreview._id !== window.location.pathname.split('/')[3]
    ) {
      setReportPreview(addReport)
      createReportConfigFromReport(addReport)
    }
  }
  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    ;(async () => {
      const check = await AppStore.checkPermission('create_report')

      if (!check) {
        showInfo(`${T.error.noPermissionTo} ${T.error.manageReport}`)

        navigate('/analytics')
      } else {
        if ((!reportId || !window.location.pathname.split('/')[3]) && reportPreview) return
        if (reportPreview) {
          createReportConfigFromReport(reportPreview)
        } else if (reportId || window.location.pathname.split('/')[3]) {
          getApiReport()
        }
      }
    })()
  }, [])

  const content = {
    setup: <CreateReportSetup reportConfig={reportConfig} setReportConfig={setReportConfig} />,
    social: <CreateReportSocials reportConfig={reportConfig} setReportConfig={setReportConfig} />,
    metrics: <CreateReportMetrics reportConfig={reportConfig} setReportConfig={setReportConfig} />,
  }

  return content[subsection]
}
