/* ------------------- IMPORTS */
import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Box, Icons } from 'components'
import { AnimatedDropdown, IItemList } from 'components/UI/AnimatedDropdown'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { AnalyticsDate } from 'types/AnalyticsInferfaces'
import { AppStore, showInfo, T } from 'utils'
import { muiTheme } from 'utils/MuiUtils/MuiThemes'
import { localDate, localLang } from 'utils/MuiUtils/MuiTranslations'

/* ------------------- TYPES  */
type TTemporalUnit = 'day' | 'week' | 'month' | 'quarter' | 'year'

/* ------------------- INTERFACES  */
interface Props {
  compareSelectedDate: AnalyticsDate | null
  setCompareSelectedDate: (dates: AnalyticsDate | null) => void
  selectedDateFilter: AnalyticsDate | null
}

export const DateCompareDropdown = ({ compareSelectedDate, setCompareSelectedDate, selectedDateFilter }: Props) => {
  /* ----------------- STATE  */
  const [activeTitle, setActiveTitle] = useState<string>(T.analytics.compare)
  // Stato per definire se è aperto il date picker
  const [openRangePicker, setOpenRangePicker] = useState<boolean>(false)

  // Stato utilizzato per l'opzione "personalizza" per far rimanere aperto il dropdown a livello di larghezza durante la scelta di una data
  const [dynamicOpenWidth, setDynamicOpenWidth] = useState<boolean>(false)

  // Stato per controllare la visualizzazione di MuiDialogActions-root
  const isInAnalytics = window.location.pathname.includes('analytics')

  /* ----------------- VARIABLES  */
  // Variabile per controllo di style
  const hasSelectedDate: boolean =
    compareSelectedDate !== null &&
    compareSelectedDate?.startDate !== undefined &&
    compareSelectedDate?.endDate !== undefined

  const howManyDaysHasSelected = selectedDateFilter
    ? dayjs(selectedDateFilter.endDate).diff(dayjs(selectedDateFilter.startDate), 'day') + 1
    : 0

  // Lista delle opzioni selezionabili nel dropdown
  const compareOptions: IItemList[] = [
    {
      name: T.date.previousDay,
      action: () => {
        deactivateDatePicker(), handleCompareDate('day')
      },
    },
    {
      name: T.date.previousWeek,
      action: () => {
        deactivateDatePicker(), handleCompareDate('week')
      },
    },
    {
      name: T.date.previousMonth,
      action: () => {
        deactivateDatePicker(), handleCompareDate('month')
      },
    },
    {
      name: T.date.previousQuarter,
      action: () => {
        deactivateDatePicker(), handleCompareDate('quarter')
      },
    },
    {
      name: T.date.previousYear,
      action: () => {
        deactivateDatePicker(), handleCompareDate('year')
      },
    },
    { name: T.analytics.customize, action: () => setOpenRangePicker(true) },
  ]

  /* ----------------- METHODS  */
  const handleCompareDate = (unit: TTemporalUnit) => {
    if (selectedDateFilter) {
      const { startDate, endDate } = selectedDateFilter
      let newStartDate: Dayjs, newEndDate: Dayjs

      switch (unit) {
        case 'day':
          newStartDate = dayjs(startDate).subtract(1, 'day')
          newEndDate = dayjs(endDate).subtract(1, 'day')
          break
        case 'week':
          newStartDate = dayjs(startDate).subtract(7, 'day')
          newEndDate = dayjs(endDate).subtract(7, 'day')
          break
        case 'month':
          newStartDate = dayjs(startDate).subtract(30, 'day')
          newEndDate = dayjs(endDate).subtract(30, 'day')
          break
        case 'quarter':
          newStartDate = dayjs(startDate).subtract(90, 'day')
          newEndDate = dayjs(endDate).subtract(90, 'day')
          break
        case 'year':
          newStartDate = dayjs(startDate).subtract(1, 'year')
          newEndDate = dayjs(endDate).subtract(1, 'year')
          break
        default:
          newStartDate = dayjs()
          newEndDate = dayjs()
          break
      }

      handleAccept([newStartDate, newEndDate])
    }
  }

  const deactivateDatePicker = () => {
    setOpenRangePicker(false)
  }

  const handleAccept = (value) => {
    // se ha valore su tutti e due i campi chiude il date picker
    if (value[0] && value[1]) {
      const selectedDays = dayjs(value[1]).diff(dayjs(value[0]), 'day') + 1

      if (selectedDays === howManyDaysHasSelected) {
        setCompareSelectedDate({ startDate: value[0], endDate: value[1] })

        setOpenRangePicker(false)
        setDynamicOpenWidth(false)
        useAnalyticsStore.getState().setCompareDatesEnabled(true)
      } else {
        showInfo(T.analytics.compareDateAlert(howManyDaysHasSelected))
      }
    }
  }

  const handleReset = () => {
    setCompareSelectedDate(null)
    useAnalyticsStore.getState().setCompareDatesEnabled(false)
    setActiveTitle(T.analytics.compare)
  }

  // Resetta il valore del compare se viene resettato il valore del filtro iniziale
  useEffect(() => {
    if (selectedDateFilter === null) {
      handleReset()
    }
  }, [selectedDateFilter])

  useEffect(() => {
    if (!dynamicOpenWidth && !hasSelectedDate) {
      setActiveTitle(T.analytics.compare)
      setOpenRangePicker(false)
    }
  }, [dynamicOpenWidth])

  return (
    <DateCompareDropdownContainer>
      <AnimatedDropdown
        key={'compare-date-dropdown'}
        icon={
          <Icons.compare
            fill={hasSelectedDate ? AppStore.theme.o.on_secondary_container : AppStore.theme.o.black}
            width={24}
            height={24}
          />
        }
        listItems={compareOptions}
        title={activeTitle}
        setTitle={setActiveTitle}
        hasValue={hasSelectedDate}
        handleReset={handleReset}
        isDisabled={selectedDateFilter === null}
        disabledText={T.date.selectDateFirst}
        dynamicOpenWidth={dynamicOpenWidth}
        setDynamicOpenWidth={setDynamicOpenWidth}
      />

      {/* Date picker per la selezione dell'opzione "personalizza" */}
      {dynamicOpenWidth && openRangePicker && (
        <ContainerDatePicker>
          <ThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={localLang} adapterLocale={localDate}>
              <StaticDateRangePicker onChange={(newValue) => handleAccept(newValue)} disableFuture />
            </LocalizationProvider>
          </ThemeProvider>
        </ContainerDatePicker>
      )}

      {/* Global style condizionale */}
      <Global
        styles={css`
          .MuiDialogActions-root {
            display: ${isInAnalytics ? 'none' : 'block'} !important;
          }
        `}
      />
    </DateCompareDropdownContainer>
  )
}

const DateCompareDropdownContainer = styled(Box)`
  position: relative;
`

const ContainerDatePicker = styled(Box)`
  position: absolute;
  background-color: ${() => AppStore.theme.o.surface};
  top: 120%;
  right: 0;
  border-radius: 14px;
  gap: 8px;
  box-shadow: ${() => AppStore.theme.smallOuterShadow};
`
