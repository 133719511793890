/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { useRef } from 'react'
import { AppStore, T, showInfo } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  value: string
  submitAction: (submitValue: string) => Promise<boolean>
  /**
   * Function that launches when the content of editable div is changed.
   * @param changedValue
   */
  actionOnInputChange?: (changedValue: string) => void
  defaultValue?: string
  minLength?: number
  maxLength?: number
  maxWidth?: number
  notifyInfo?: string
}

export const InputEditableContent = ({
  value,
  submitAction,
  actionOnInputChange,
  defaultValue = 'Default Text',
  minLength = 1,
  maxLength = 25,
  maxWidth = 280,
  notifyInfo,
}: Props) => {
  /* ----------------- REF  */
  const editableDivInputRef = useRef<HTMLDivElement>(null)

  /* ----------------- METHODS  */
  const currentContent = (): string => editableDivInputRef.current?.innerText ?? ''

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    const isCtrlOrCmdClicked = e.metaKey || e.ctrlKey

    if (e.key === 'Enter') {
      editableDivInputRef.current?.blur()
    }

    if (!currentContent()) return

    if (currentContent().length === maxLength + 1 && e.key !== 'Backspace' && !isCtrlOrCmdClicked) {
      e.preventDefault()
    }
  }

  const handleOnInput = () => {
    if (!currentContent()) editableDivInputRef.current?.classList.add('content-error')
    else {
      editableDivInputRef.current?.classList.remove('content-error')

      if (currentContent().length >= minLength && currentContent().length <= maxLength) {
        if (actionOnInputChange) actionOnInputChange(currentContent())
      } else {
        showInfo(notifyInfo ?? T.analytics.reportRename(minLength, maxLength))
      }
    }
  }

  const handleBlur = () => {
    if (!currentContent()) {
      editableDivInputRef.current?.classList.remove('content-error')
      return (editableDivInputRef.current!.innerText = defaultValue)
    } else {
      submitCheck()
      editableDivInputRef.current!.scrollLeft = 0
    }
  }

  const handleClick = (e) => {
    e.stopPropagation()
    editableDivInputRef.current!.scrollLeft = editableDivInputRef.current?.innerText.length ?? 0
    editableDivInputRef.current?.classList.remove('content-error')
  }

  const submitCheck = async () => {
    if (currentContent() === defaultValue) return editableDivInputRef.current?.blur()

    const response = await submitAction(currentContent())
    if (!response) {
      return editableDivInputRef.current?.classList.add('content-error')
    }
  }

  return (
    <InputEditableContentContainer
      ref={editableDivInputRef}
      contentEditable
      onInput={handleOnInput}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onClick={(e) => handleClick(e)}
      maxWidth={maxWidth}
    >
      {value}
    </InputEditableContentContainer>
  )
}

const InputEditableContentContainer = styled.div<{ maxWidth: number }>`
  font-size: 21px;
  font-weight: 500;
  height: 32px;
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  overflow-wrap: break-word;
  width: fit-content;
  min-width: 100px;
  max-width: ${({ maxWidth }) => `${maxWidth}px`};
  border-radius: 8px;
  background: transparent;
  color: ${() => AppStore.theme.o.black};
  padding: 0px 8px 4px 8px;
  overflow-x: hidden;
  border: 1px solid transparent;
  transition: 0.3s;

  :hover,
  :focus {
    background: ${() => AppStore.theme.o.grey};
  }

  :focus {
    text-overflow: unset;
    overflow-x: scroll;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }

  &.content-error {
    border: 1px solid ${() => AppStore.theme.o.red};
    animation: shake 0.3s ease-in-out;
  }
`
