/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import asters_placeholder from 'assets/asters_placeholder.png'
import { Box, Icons } from 'components'
import { useState } from 'react'
import { AppStore } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  post
}

export const TableRowFirstColumn = ({ post }: Props) => {
  /* ----------------- STATE  */
  const [isHovered, setIsHovered] = useState(false)

  console.log('post', post)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */
  const formatDate = (date: Date) => {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const hours = date.getHours()
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${day}/${month} - ${hours}:${minutes}`
  }

  const checkTypeIcon = (mediaType: string) => {
    //   STANDARD: <Icons.postIcon fill={AppStore.theme.o.black} />,
    //   CAROUSEL: <Icons.carouselIcon fill={AppStore.theme.o.black} />,
    //   VIDEO: <Icons.videoIcon fill={AppStore.theme.o.black} />,
    //   REEL: <Icons.reelIcon fill={AppStore.theme.o.black} />,
    //   SHORT: <Icons.shortStoryIcon fill={AppStore.theme.o.black} />,
    //   STORY: <Icons.shortStoryIcon fill={AppStore.theme.o.black} />,
    //   LINK: <Icons.link fill={AppStore.theme.o.black} />,

    console.log('mediaType', mediaType)
    switch (mediaType) {
      case 'IMAGE':
        return <Icons.postIcon fill={AppStore.theme.o.black} />
    }
  }

  const formatTemporalValue = (seconds: number) => {
    const h = Math.floor(seconds / 3600)
    const m = Math.floor((seconds % 3600) / 60)
    const s = seconds % 60

    const hDisplay = h > 0 ? `${h}:` : ''
    const mDisplay = m > 0 ? `${m}:` : h > 0 ? '00:' : ''
    const sDisplay = s.toString().padStart(m > 0 ? 2 : 1, '0')

    return `${hDisplay}${mDisplay}${sDisplay}`
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  const MediaElement = () => {
    const type = post.media_type == 'video' ? 'video' : 'image'
    return (
      <ImgContainer>
        {type === 'image' && <ItemImg src={post.imageUrl ?? post.thumbnailUrl ?? asters_placeholder} />}
        {type === 'video' && <ItemVideo src={post.imageUrl} muted autoPlay={false} />}
        {post && post.video_duration && <VideoDuration>{formatTemporalValue(post.video_duration)}</VideoDuration>}
      </ImgContainer>
    )
  }

  return (
    <RowItemContainer onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      {isHovered && (
        <ItemContent>
          <ItemHeader>
            <ItemType>{checkTypeIcon(post.content_type)}</ItemType>
            <ItemDate>{formatDate(new Date(post.createdAt))}</ItemDate>
          </ItemHeader>

          <ItemMessage>{post.message}</ItemMessage>
        </ItemContent>
      )}

      <MediaElement />
      <ItemDate>{formatDate(new Date(post.createdAt))}</ItemDate>
    </RowItemContainer>
  )
}

const RowItemContainer = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  position: relative;
`

const ImgContainer = styled(Box)`
  margin-top: 8px;
  position: relative;
`

const VideoDuration = styled(Box)`
  position: absolute;
  bottom: 4px;
  right: 4px;

  background-color: ${() => AppStore.theme.o.surface};
  padding: 4px 6px;
  font-size: 12px;
  color: ${() => AppStore.theme.o.black};
`

const ItemImg = styled.img`
  width: 83px;
  height: 83px;
  border-radius: 14px;
  object-fit: cover;
`

const ItemVideo = styled.video`
  width: 83px;
  height: 83px;
  border-radius: 14px;
  object-fit: cover;
`

const ItemContent = styled(Box)`
  position: absolute;
  top: 0;
  left: calc(100% + 8px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 253px;

  background-color: ${() => AppStore.theme.o.surface};
  padding: 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
`

const ItemHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const ItemType = styled(Box)`
  width: 24px;
  height: 24px;
  color: ${() => AppStore.theme.o.black};
`

const ItemDate = styled(Box)`
  font-size: 12px;
  color: ${() => AppStore.theme.o.black};
  text-align: center;
`

const ItemMessage = styled.p`
  font-size: 12px;
  color: ${() => AppStore.theme.o.black};
  white-space: pre-line;
`
