import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useEffect, useState } from 'react'
import { AppStore, T } from 'utils'
import { Permissions } from './UserPermission'
interface Props {
  listOfPermissions: Permissions
  sectionIndex: number
  onPermissionUpdate: (rule: string | string[]) => void
}

export default function PermissionsSettingComponent(props: Props) {
  /* ----------------- STATE  */
  const [permissions, setPermissions] = useState<Permissions>()
  const [fillColorSelected, setFillColorSelected] = useState(AppStore.theme.o.grey)
  const [colorSelected, setColorSelected] = useState(AppStore.theme.o.black)
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const [sectionValue, setSectionValue] = useState<boolean>(false)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */

  /* ----------------- METHODS  */

  const onRuleUpdate = (rule: string | string[]) => {
    props.onPermissionUpdate(rule)
  }

  const handleSectionToggle = () => {
    const prevData = permissions
    if (permissions) {
      setPermissions((prevPermissions) => {
        if (prevPermissions) {
          const prevState = prevPermissions.sectionEnabled
          const updatedPermissions = {
            ...prevPermissions,
            sectionEnabled: !prevPermissions.sectionEnabled,
          }

          if (prevState) {
            updatedPermissions.rules = updatedPermissions.rules.map((rule) => ({
              ...rule,
              enabled: false,
            }))
          } else {
            updatedPermissions.rules = updatedPermissions.rules.map((rule) => {
              if (rule.ruleTitle.includes(T.teamMembers.comingSoon)) {
                return {
                  ...rule,
                  enabled: false,
                }
              } else {
                return {
                  ...rule,
                  enabled: true,
                }
              }
            })
          }

          const arrayToSend: any[] = []
          if (prevData === undefined) return
          for (let i = 0; i < prevData?.rules.length; i++) {
            const rule1 = prevData?.rules[i]
            const rule2 = updatedPermissions.rules[i]

            if (rule1.enabled !== rule2.enabled) {
              arrayToSend.push(rule1.id)
            }
          }

          onRuleUpdate(arrayToSend)

          return updatedPermissions
        }
      })
    }
  }

  const handleRuleToggle = (ruleIndex: number) => {
    if (permissions) {
      setPermissions((prevPermissions) => {
        if (prevPermissions) {
          const updatedPermissions = {
            ...prevPermissions,
            rules: prevPermissions.rules.map((rule, index) => {
              if (index === ruleIndex) {
                onRuleUpdate(rule.id)
                return {
                  ...rule,
                  enabled: !rule.enabled,
                }
              }
              return rule
            }),
          }

          if (!prevPermissions.sectionEnabled) updatedPermissions.sectionEnabled = sectionValue

          return updatedPermissions
        }
      })
    }
  }

  const checkPermissions = () => {
    // All checked
    if (permissions?.rules.every((rule) => rule.enabled === true)) {
      setColorSelected(AppStore.theme.o.green)
      setFillColorSelected(AppStore.theme.o.lightGreen)
      setSectionValue(true)
    }

    // Some checked
    else if (permissions?.rules.some((rule) => rule.enabled === true)) {
      setColorSelected(AppStore.theme.o.black)
      setFillColorSelected(AppStore.theme.o.grey)
      setSectionValue(true)
    }

    // All unchecked
    else if (!permissions?.rules.every((rule) => rule.enabled === true)) {
      setColorSelected(AppStore.theme.o.grey)
      setFillColorSelected(AppStore.theme.o.lightGrey)
      setSectionValue(false)
    }
  }

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    setPermissions(props.listOfPermissions)
  }, [])

  useEffect(() => {
    checkPermissions()
  }, [permissions])

  return (
    <ContainerPermissions flex mr={8} style={{ maxHeight: isExpanded ? '100%' : '75px', height: '100%' }}>
      <PermissionTitleContainer vcenter row>
        <Box row center gap={16}>
          <PermissionTitle style={{ color: sectionValue ? AppStore.theme.o.black : AppStore.theme.o.darkGrey }}>
            {permissions?.title}
          </PermissionTitle>

          <Box onClick={() => handleSectionToggle()}>
            <ContainerBall
              style={{
                backgroundColor: sectionValue ? fillColorSelected : AppStore.theme.o.lightGrey,
                outline: `1px solid ${sectionValue ? colorSelected : AppStore.theme.o.darkGrey}`,
              }}
            >
              <Ball
                style={{
                  backgroundColor: sectionValue ? colorSelected : AppStore.theme.o.selectPermissionButton,
                }}
                isActive={sectionValue}
              />
            </ContainerBall>
          </Box>
        </Box>

        <Box onClick={() => setIsExpanded((prev) => !prev)}>
          <Icons.dropdownArrow
            width={32}
            height={32}
            fill={AppStore.theme.o.black}
            style={{
              backgroundColor: 'transparent',
              rotate: isExpanded ? '0deg' : '-180deg',
              transition: 'all 0.3s',
            }}
          />
        </Box>
      </PermissionTitleContainer>

      {permissions?.rules.map((element, index) => (
        <Box style={{ opacity: isExpanded ? 1 : 0, pointerEvents: isExpanded ? 'auto' : 'none' }}>
          <PermissionContainer removeHover row center justify="space-between">
            <PermissionText style={{ color: element.enabled ? AppStore.theme.o.black : AppStore.theme.o.darkGrey }}>
              {element.ruleTitle}
            </PermissionText>

            <Box onClick={() => handleRuleToggle(index)} mr={'8px'}>
              <ContainerBall
                style={{
                  backgroundColor: element.enabled ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightGrey,
                  outline: `1px solid ${element.enabled ? AppStore.theme.o.green : AppStore.theme.o.darkGrey}`,
                }}
              >
                <Ball
                  style={{
                    backgroundColor: element.enabled ? AppStore.theme.o.green : AppStore.theme.o.selectPermissionButton,
                  }}
                  isActive={element.enabled}
                />
              </ContainerBall>
            </Box>
          </PermissionContainer>

          {index + 1 !== permissions.rules.length && <Divider />}
        </Box>
      ))}
    </ContainerPermissions>
  )
}

const ContainerPermissions = styled(Box)`
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  z-index: 1;
`

const PermissionTitleContainer = styled(Box)`
  padding: 12px 32px;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  border-bottom: 1px solid ${() => AppStore.theme.o.lightGrey};
  height: 75px;
`

const PermissionContainer = styled(Box)`
  padding: 12px 32px 12px 32px;
`

const PermissionText = styled.p`
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.12px;
`

const PermissionTitle = styled.p`
  font-family: 'Aktiv Grotesk';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;
`

const Divider = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${() => AppStore.theme.o.lightGrey};
`

const ContainerBall = styled(Box)`
  height: 20px;
  width: 40px;
  border-radius: 11.79px;
  position: relative;
`

const Ball = styled(Box)<{ isActive?: boolean }>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  ${({ isActive }) =>
    isActive
      ? `
    right: 2px;

  `
      : `
    left: 2px;

  `}
`
