import styled from '@emotion/styled'
import greensap from 'gsap'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import { useCalendarStore } from 'stores/CalendarStore'
import { useMenuNavigationStore } from 'stores/MenuNavigationStore'
import { useNotifyStore } from 'stores/NotifyStore'
import { T } from 'translation/i18n'
import { Workspace } from 'types'
import { AppStore } from 'utils'
import { isVisible } from 'utils/CheckVisibleElement'
import { version } from '../../package.json'
import { Box } from './Box'
import { Button } from './Button'
import { Icons } from './Icons'
import { Text } from './Text'
import { NotificationIcon } from './UI'
import { WorkspaceIcon } from './WorkspaceIcon'
import { useUserStore } from 'stores/UserStore'
import { courtesyRoutePath } from 'utils/UsersUtils'

interface MenuItem {
  key: string
  icon: JSX.Element
  color?: string
  href?: string
  tooltipDescription?: string
  current?: any
  active?: boolean
  disabled?: boolean
  iconActive?: JSX.Element
  iconHover?: JSX.Element
  label?: string
  activeColor?: string
  planValid?: boolean
}

interface State {
  workspaceVisible: boolean
  addWorkspaceVisible: boolean
  addWorkspaceHover: boolean
  active: number
  items: MenuItem[]
  hoverIndex: number
}

const iconStyle: CSSProperties = {
  minWidth: 40,
  minHeight: 40,
}

const deactiveOption = {
  x: 0,
  ease: 'elastic.out(1, 1)',
  duration: 1,
}

const activeOption = {
  x: 26,
  duration: 1,
  ease: 'elastic.out(1, 1)',
}

interface Props {
  style?: any
  router: any
}

const Menu = ({ style, router }: Props) => {
  // check if current route is /subscription-expired

  // -------------- ZUSTAND
  const { year, month } = useCalendarStore((s) => ({
    year: s.year,
    month: s.month,
  }))

  const { currentUser } = useUserStore((s) => ({
    currentUser: s.user,
  }))

  // Check user workspaces
  const collabWorkspaces = currentUser?.collabWorkspace.map((w) => w.workspace)
  const currentPlan = currentUser?.subscription?.activePlan
  const isUserPlanValid =
    currentUser?.enterprise ||
    (currentPlan && currentPlan.expirationDate && new Date(currentPlan.expirationDate) > new Date())

  // check if current route is forced from the system
  const isForceVisibility =
    window.location.pathname === courtesyRoutePath.subscription_expired ||
    window.location.pathname === courtesyRoutePath.email_validation ||
    window.location.pathname === courtesyRoutePath.subscription_expired_free_trial

  // -------------- METHODS
  const handleCalendarLink = () => {
    const date = `${year}-${month + 1}`

    return `/calendar?view=month&date=${date}`
  }

  /* ------------------------------------ STATE */
  const [state, setState] = useState<State>({
    active: -1,
    addWorkspaceVisible: false,
    workspaceVisible: false,
    addWorkspaceHover: false,
    hoverIndex: -1,

    items: [
      {
        key: 'logo',
        icon: <Icons.logo style={iconStyle} />,
        href: '/dashboard',
      },
      {
        key: 'post',
        tooltipDescription: T.sidebarNavigation.post,
        icon: <Icons.createPost style={iconStyle} />,
        iconActive: <Icons.createPost fill="#072528" style={iconStyle} />,
        iconHover: <Icons.createPost fill={AppStore.theme.o.blueOnLight} style={iconStyle} />,
        href: '/post/preview/basic',
        active: window.location.pathname.startsWith('/post'),
        label: T.postPage.createPost,
        color: '#1DA0AC',
        planValid: true,
      },
      {
        key: 'discover',
        tooltipDescription: T.sidebarNavigation.discover,
        icon: <Icons.inbox fill={AppStore.theme.o.black} style={iconStyle} />,
        iconActive: <Icons.inbox fill="#6d4a05" style={iconStyle} />,
        iconHover: <Icons.inbox fill={AppStore.theme.o.blueOnLight} style={iconStyle} />,
        activeColor: '#6d4a05',
        href: '/discover',
        active: window.location.pathname.startsWith('/discover'),
        label: 'AI Discover',
        color: '#F6B941',
        planValid: true,
      },
      {
        key: 'dashboard',
        tooltipDescription: T.sidebarNavigation.dashboard,
        icon: <Icons.dashboard fill={AppStore.theme.o.black} style={iconStyle} />,
        iconActive: <Icons.dashboard fill="#114d31" style={iconStyle} />,
        iconHover: <Icons.dashboard fill={AppStore.theme.o.blueOnLight} style={iconStyle} />,
        activeColor: '#114d31',
        href: '/dashboard',
        active: window.location.pathname.startsWith('/dashboard') || window.location.pathname === '/',
        label: 'Dashboard',
        color: '#73E4AF',
        planValid: true,
      },
      {
        key: 'calendar',
        tooltipDescription: T.sidebarNavigation.calendar,
        icon: <Icons.calendar fill={AppStore.theme.o.black} style={iconStyle} />,
        iconActive: <Icons.calendar fill="#6d4a05" style={iconStyle} />,
        iconHover: <Icons.calendar fill={AppStore.theme.o.blueOnLight} style={iconStyle} />,
        activeColor: '#6d4a05',
        href: handleCalendarLink(),
        active: window.location.pathname.startsWith('/calendar'),
        label: 'Calendario',
        color: '#F6B941',
        planValid: true,
      },
      {
        key: 'media',
        tooltipDescription: T.sidebarNavigation.media,
        icon: <Icons.media fill={AppStore.theme.o.black} style={iconStyle} />,
        iconActive: <Icons.media fill="#114d31" style={iconStyle} />,
        iconHover: <Icons.media fill={AppStore.theme.o.blueOnLight} style={iconStyle} />,
        activeColor: '#114d31',
        href: '/media',
        active: window.location.pathname.startsWith('/media'),
        label: 'Media',
        color: '#73E4AF',
        planValid: true,
      },

      {
        key: 'analytics',
        tooltipDescription: T.sidebarNavigation.analytics,
        icon: <Icons.analytics fill={AppStore.theme.o.black} style={iconStyle} />,
        iconActive: <Icons.analytics fill="#380d16" style={iconStyle} />,
        iconHover: <Icons.analytics fill={AppStore.theme.o.blueOnLight} style={iconStyle} />,
        activeColor: '#380d16',
        href: '/analytics',
        active: window.location.pathname.startsWith('/analytics'),
        label: 'Analytics',
        color: '#D85A74',
        planValid: true,
      },

      {
        key: 'workspace',
        icon: <WorkspaceIcon workspace={AppStore.workspace} hideName />,
      },
    ],
  })
  const [timer, setTimer] = useState<any>(null)
  const [activePage, setActivePage] = useState<string>('')

  /* ------------------------------------ ZUSTAND */

  const setLocationPage = useMenuNavigationStore.getState().setLocationPage

  // serve a gestire il cambio di pagina da parte del router
  const unsubRouterActivePage = useMenuNavigationStore.subscribe((s) => {
    if (s.locationPage !== activePage) {
      setActivePage(s.locationPage)

      setState((prev) => ({ ...prev, active: prev.items.findIndex((i) => i.key === s.locationPage) }))
    }
  })

  /* ------------------------------------ REF */

  const itemRefs = useRef<Array<HTMLDivElement>>([])
  const indicatorRef = useRef<SVGSVGElement>(null)
  const indicatorBubbleRef = useRef<HTMLDivElement>(null)

  /* ------------------------------------ METHODS */

  const animate = (time = 0) => {
    // Se il piano non è valido non esegue animazioni
    if (!isUserPlanValid) return
    if (timer) {
      clearTimeout(timer)
      setTimer(null)
    }

    setTimer(
      setTimeout(() => {
        const menuContainer = document.getElementById('menu-container')?.getBoundingClientRect()

        const activeMenu = document.getElementById('activeMenu')?.getBoundingClientRect()
        itemRefs.current.forEach((c, index) => greensap.to(c, index === state.active ? activeOption : deactiveOption))

        const rectY = activeMenu?.y ?? 0
        const rectHeight = activeMenu?.height ?? 0
        let posY = rectY + rectHeight / 2 - 3

        const zoomProp = 100 / AppStore.zoom

        if (time !== 0) {
          posY = posY * zoomProp
        }

        if (indicatorRef.current)
          greensap.to(indicatorRef.current as any, {
            x: 54.5 / zoomProp,
            y: posY,
            width: (menuContainer?.width ?? 0) * 0.8,
            ease: 'elastic.out(1, 1)',
            duration: 1.2,
          })

        if (indicatorBubbleRef.current)
          greensap.to(indicatorBubbleRef.current as any, {
            x: 70 / zoomProp,
            y: posY,
            width: (activeMenu?.width ?? 0) + 10,
            height: (activeMenu?.width ?? 0) + 10,
            ease: 'elastic.out(1, 1)',
            duration: 1.2,
          })
      }, time)
    )
  }

  const onMenuOptionClick = (item, index) => {
    const { active } = state

    router.navigate(item.href)

    if (item.key === 'workspace') return setState({ ...state, workspaceVisible: true })
    if (active === index) return
    if (item.key === 'logo') {
      setState({ ...state, active: 3 }) // "3" è la pozione della dashboard
      setLocationPage('dashboard')
    } else if (!item.disabled) {
      setState({ ...state, active: index })
      setLocationPage(item.key)
    }
  }

  const onMenuMouseEnter = (item) => {
    if (item.key === 'workspace') return setState({ ...state, workspaceVisible: true })
  }

  /* ------------------------------------ USE-EFFECT */
  useEffect(() => {
    setState((prevState) => {
      setLocationPage(prevState.items.find((i) => i.active)?.key ?? '')
      return {
        ...prevState,
        active: prevState.items.findIndex((i) => i.active),
      }
    })

    return () => {
      unsubRouterActivePage()

      setState((prevState) => ({
        ...prevState,
        active: prevState.items.findIndex((i) => i.active),
      }))
    }
  }, [])

  useEffect(() => {
    AppStore.setActiveMenu = (_active) => {
      // setActive(_active)
      animate()
    }

    AppStore.setActivePathMenu = (path) => {
      const activePath = state.items.findIndex((i) => i.href?.startsWith(path) && i.key !== 'logo')
      if (activePath === state.active) return
      setState({ ...state, active: activePath })
      animate()
    }

    AppStore.addResizeCallback(() => {
      AppStore.removeResizeCallback('resizeMenu')
      animate(1)
    }, 'resizeMenu')

    const popstateHandler = (e) => {
      const path = e.target?.pathname || window.location.pathname
      AppStore.setActivePathMenu(path)
    }

    window.addEventListener('popstate', popstateHandler)

    animate(0)

    return () => {
      AppStore.removeResizeCallback('resizeMenu')
      window.removeEventListener('popstate', popstateHandler)
    }
  }, [state])

  // ---- temp ---- to be removed when notification management will be corrected

  const getNotificationCount = (workspaceId: string) => {
    return useNotifyStore.getState().getWorkspaceNotificationsNumber(workspaceId)
  }

  return !isForceVisibility ? (
    <>
      <MenuContainer
        id="menu-container"
        justify={'space-around'}
        style={{ zIndex: 1, backgroundColor: AppStore.theme.o.surface, ...style }}
      >
        {state.items
          .filter((item) => item.planValid === undefined || (item.planValid && isUserPlanValid) || !item.planValid)
          .map((item, index) => (
            <MenuOption
              active={state.active === index}
              key={item.key}
              ref={(r) => (itemRefs.current[index] = r!)}
              onClick={() => {
                onMenuOptionClick(item, index)
              }}
              onMouseEnter={() => onMenuMouseEnter(item)}
              style={{ backgroundColor: 'transparent', position: 'relative', zIndex: 1000 }}
              id={`${item.key}-menu`}
            >
              {isVisible('menu.versionText') && index === 0 && (
                <>
                  <VersionText style={{ top: '80px' }}>{T.settings.version}</VersionText>
                  <VersionText style={{ top: '95px' }}>{version}</VersionText>
                </>
              )}

              <IconContainer
                active={state.active === index}
                color={item.activeColor || ''}
                itemKey={item.key as string}
                center
                style={{ margin: 'auto', zIndex: 3 }}
              >
                {state.active !== index && <div>{item.icon}</div>}
                <TooltipDescription className={item.tooltipDescription ? 'tooltipShow' : ''}>
                  {item.tooltipDescription}
                </TooltipDescription>
                {/* {state.active !== index && <div>{item.iconHover}</div>} */}

                {state.active === index && <div id="activeMenu">{item.iconActive}</div>}
              </IconContainer>
              {state.active === index && (
                <Box
                  style={{
                    position: 'absolute',
                    backgroundColor: 'transparent',
                    zIndex: 2,
                    top: 25,
                    width: '50%',
                    height: '45%',
                  }}
                />
              )}
            </MenuOption>
          ))}

        {state.active >= 0 && isUserPlanValid && (
          <Indicator>
            <Icons.navbarMask
              fill={AppStore.theme.o.menuIndicator}
              id="indicator"
              style={{
                position: 'absolute',
                minWidth: 40,
              }}
              ref={indicatorRef}
            />
            <IndicatorBubble
              backgroundColor={state.items[state.active].color ?? 'black'}
              id="indicatorBubble"
              ref={indicatorBubbleRef}
            />
          </Indicator>
        )}
      </MenuContainer>

      {state.workspaceVisible && (
        <div
          onClick={() => setState({ ...state, workspaceVisible: false })}
          style={{ zIndex: 9999, position: 'fixed', bottom: 0, left: 0 }}
        >
          <Box
            animation="fade"
            onMouseLeave={() => setState({ ...state, workspaceVisible: false })}
            style={{ position: 'fixed', bottom: 0, left: 0, width: 350 }}
            bgPrimaryColor
            shadow={AppStore.theme.bigOuterShadow}
            p={24}
            gap={24}
          >
            {/* MY WORKSPACES */}
            <Box bc={AppStore.theme.o.lightestGrey} pv={24} pl={24} pr={8} gap={16}>
              {/* HEADER */}
              <TextWorkspace>{T.settings.yourWorkspaces}</TextWorkspace>

              {/* List */}
              <ContainerListWorkspaces>
                {currentUser?.workspaces.map((workspace: Workspace) => {
                  return (
                    <WorkspaceContainer
                      key={workspace._id}
                      onClick={() => AppStore.setActiveWorkspace(workspace._id!)}
                      p={10}
                      row
                      vcenter
                    >
                      <WorkspaceIcon workspace={workspace} />
                      <NotificationIcon count={getNotificationCount(workspace._id!)} />
                    </WorkspaceContainer>
                  )
                })}
              </ContainerListWorkspaces>
            </Box>

            {/* COLLAB WORKSPACES */}
            {currentUser?.collabWorkspace && currentUser?.collabWorkspace?.length > 0 && (
              <Box bc={AppStore.theme.o.lightestGrey} pv={24} pl={24} pr={8} gap={16}>
                {/* HEADER */}
                <TextWorkspace>{T.settings.sharedWorkspaces}</TextWorkspace>

                {/* List */}
                <ContainerListWorkspaces>
                  {collabWorkspaces?.map((workspace: Workspace) => {
                    return (
                      <WorkspaceContainer
                        key={workspace._id}
                        onClick={() => AppStore.setActiveWorkspace(workspace._id!)}
                        p={10}
                        row
                        vcenter
                      >
                        <WorkspaceIcon workspace={workspace} />
                        <NotificationIcon count={getNotificationCount(workspace._id!)} />
                      </WorkspaceContainer>
                    )
                  })}
                </ContainerListWorkspaces>
              </Box>
            )}

            {/* BTN ADD WORKSPACE */}
            {AppStore.workspace?.user?.toString() === currentUser?._id.toString() && (
              <Button
                id="add-workspace-button-setting"
                variant="secondary"
                onClick={() => {
                  router.navigate('/settings/workspace')
                }}
                ph={48}
                pv={8}
                style={{
                  fontSize: 14,
                  gap: 8,
                }}
              >
                <Icons.add style={{ marginLeft: 3, height: 24, width: 24 }} fill={AppStore.theme.o.green} />

                {T.settings.addEnv}
              </Button>
            )}

            {/* ACTIVE WORKSPACE */}
            <Box bc={AppStore.theme.o.lightestGrey} pv={24} pl={24} pr={8} gap={16}>
              {/* HEADER */}
              <TextWorkspace>{T.postPage.currentWorkspace}</TextWorkspace>

              <WorkspaceCurrent p={10} row vcenter mr={12}>
                <WorkspaceIcon workspace={AppStore.workspace} />
              </WorkspaceCurrent>
            </Box>
          </Box>
        </div>
      )}
    </>
  ) : (
    <></>
  )
}
export default Menu

const ContainerListWorkspaces = styled(Box)`
  overflow-y: scroll;
  gap: 8px;
  max-height: 35vh;
  padding-right: 8px;
`

const VersionText = styled((props) => <Text {...props} />)`
  font-size: 10px !important;
  color: ${() => AppStore.theme.o.black} !important;
  font-weight: 800 !important;
  position: absolute;
`

const TextWorkspace = styled.p`
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${() => AppStore.theme.o.black};
  white-space: nowrap; /* Impedisce l'inviluppo del testo */
  overflow: hidden; /* Nasconde il contenuto in eccesso */
  text-overflow: ellipsis; /* Mostra i tre puntini quando il contenuto sfora */
`

const DropdownIcon = styled(Icons.downIcon)`
  width: 24px;
  height: 24px;
  fill: ${() => AppStore.theme.o.black};
`

const WorkspaceContainer = styled(Box)`
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px solid ${() => AppStore.theme.o.grey};

  :hover {
    background-color: ${() => AppStore.theme.o.lightBlue};
    border: 1px solid ${() => AppStore.theme.o.lightBlue};
  }
`
const WorkspaceCurrent = styled(Box)`
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px solid ${() => AppStore.theme.o.grey};
`

const MenuContainer = styled(Box)`
  border-radius: 0;
  ${() => AppStore.theme.bigOuterShadow}
  width: 90px;
`

const MenuOption = styled.div<{ active: boolean }>`
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  height: 100px;

  &:hover {
    .tooltipShow {
      opacity: 1 !important;
      transform: translateX(0) !important;
    }
  }
`

const IndicatorBubble = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 60px;
  width: 60px;
  border-radius: 60px;
  /* left: 0px; */
  position: absolute;
`

const Indicator = styled.div`
  transform: scale(${() => 100 / AppStore.zoom});
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  //** box-shadow: inset 7px 0px 3px 0px rgb(87 87 87 / 1%); **/
`

const IconContainer = styled(Box)<{ active: boolean; color: string; itemKey: string }>`
  svg {
    position: relative;
    transition: all 0.3s;
    padding: 4px;
    border-radius: 14px;
    fill: ${({ active, color }) => (active ? color : AppStore.theme.o.black)};
  }

  :hover {
    svg {
      background-color: ${({ active }) => (active ? 'transparent' : AppStore.theme.o.lightBlue)};
      fill: ${({ active }) => !active && AppStore.theme.o.blueOnLight};
      ${({ itemKey }) => itemKey === 'logo' && `background-color: transparent !important`}
    }
  }
`

const TooltipDescription = styled.div`
  background-color: ${() => AppStore.theme.o.lightBlue};
  color: ${() => AppStore.theme.o.blue};
  font-size: 16px;
  font-weight: 600;
  padding: 8px 8px;
  position: absolute;
  left: 75px;
  top: 27px;
  border-radius: 8px;

  opacity: 0;
  transform: translateX(-80%);
  transition: opacity 0.3s ease, transform 0.3s ease;
`
