import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import { compareAndMergeArrays } from 'api/Utils'
import Workspaces from 'api/Workspaces'
import { Box, Button } from 'components'
import { Icons } from 'components/Icons'
import { useEffect, useState } from 'react'
import { Workspace } from 'types'
import { AppStore, T, showError, showInfo } from 'utils'
import { TRACKEREVENTS, createMixpanelWorkspaceTeamData } from 'utils/eventsTracker'
import { navigate } from 'utils/router'
import { openRenameModal } from './Modal/ModalManager'
import PermissionsSettingComponent from './PermissionsSettingComponent'

interface Props {
  changeSection: (section: string, workspace?: Workspace) => void
  workspace: Workspace
  onWorkspaceChange: (workspace: Workspace) => void
}

interface Rule {
  id: string
  ruleTitle: string
  enabled: boolean
}

export interface Permissions {
  title: string
  sectionEnabled: boolean
  rules: Rule[]
}

const UserPermission = ({ changeSection, workspace, onWorkspaceChange }: Props) => {
  /* ----------------- STATE  */
  const [roleName, setRoleName] = useState<string | undefined>('New Role')
  const [rules, setRules] = useState<Array<string>>([])
  const [permissionsToRender, setPermissionsToRender] = useState<Permissions[]>([])

  /* ----------------- VARIABLES  */
  const permissions: Permissions[] = [
    {
      title: T.teamMembers.rulesTitle.publication,
      sectionEnabled: false,
      rules: [
        {
          id: 'edit_post',
          ruleTitle: T.teamMembers.rules.editPost,
          enabled: false,
        },
        {
          id: 'publish_post',
          ruleTitle: T.teamMembers.rules.publishPost,
          enabled: false,
        },
        {
          id: 'ai_copy_generator',
          ruleTitle: T.teamMembers.rules.aiCopyGenerator,
          enabled: false,
        },
        {
          id: 'manage_draft',
          ruleTitle: T.teamMembers.rules.manageDraft,
          enabled: false,
        },
      ],
    },

    {
      title: T.teamMembers.rulesTitle.calendar,
      sectionEnabled: false,
      rules: [
        {
          id: 'calendar_show_posts',
          ruleTitle: T.teamMembers.rules.calendarShowPost,
          enabled: false,
        },
      ],
    },

    {
      title: T.teamMembers.rulesTitle.media,
      sectionEnabled: false,
      rules: [
        {
          id: 'upload_media',
          ruleTitle: T.teamMembers.rules.uploadMedia,
          enabled: false,
        },
        {
          id: 'manage_media',
          ruleTitle: T.teamMembers.rules.manageMedia,
          enabled: false,
        },
        {
          id: 'media_editor',
          ruleTitle: T.teamMembers.rules.mediaEditor,
          enabled: false,
        },
      ],
    },

    {
      title: T.teamMembers.rulesTitle.analytics,
      sectionEnabled: false,
      rules: [
        {
          id: 'create_report',
          ruleTitle: T.teamMembers.rules.createReport,
          enabled: false,
        },
        {
          id: 'view_report',
          ruleTitle: T.teamMembers.rules.viewReport,
          enabled: false,
        },
        {
          id: 'share_report',
          ruleTitle: T.teamMembers.rules.shareReport,
          enabled: false,
        },
      ],
    },

    {
      title: T.teamMembers.rulesTitle.discover,
      sectionEnabled: false,
      rules: [
        {
          id: 'search_content',
          ruleTitle: T.teamMembers.rules.searchContent,
          enabled: false,
        },
        {
          id: 'manage_discover',
          ruleTitle: T.teamMembers.rules.manageContentDiscover,
          enabled: false,
        },
      ],
    },

    {
      title: T.teamMembers.rulesTitle.settings,
      sectionEnabled: false,
      rules: [
        {
          id: 'link_social_account',
          ruleTitle: T.teamMembers.rules.linkSocialAccount,
          enabled: false,
        },
        {
          id: 'delete_social_account',
          ruleTitle: T.teamMembers.rules.deleteSocialAccount,
          enabled: false,
        },
        {
          id: 'edit_workspaces',
          ruleTitle: T.teamMembers.rules.editWorkspaces,
          enabled: false,
        },
      ],
    },
  ]

  const evenData = createMixpanelWorkspaceTeamData({
    role_name: roleName,
    role_permission: rules,
    workspace_name: workspace.name,
  })

  /* ----------------- ROUTING  */
  const url = window.location.search
  const queryParameters = new URLSearchParams(url)
  const token = queryParameters.get('id') ?? ''
  const newRoleName = queryParameters.get('newrole') ?? ''
  const changeName = queryParameters.get('changeName') ?? ''

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- METHODS  */
  const handleConfirmButton = () => {
    if (permissionsToRender.length === 0) {
      Tracker.trackEvent(TRACKEREVENTS.WORKSPACE_ROLE_CREATION_SUCCESSFUL, evenData)
      return
    }

    //@sal - roba orribile ---------------- non da risistemare/da rifare tutto il componente team members
    changeName ? createRole() : modifyRole(token)
    onChangeSection('management')
    showInfo(T.info.roleUpdated)
  }

  const onChangeSection = (section: string) => {
    changeSection(section, workspace)
  }

  const onPermissionUpdate = (rulesReceived: string | string[]) => {
    if (Array.isArray(rulesReceived)) {
      const updatedRules = compareAndMergeArrays(rules, rulesReceived)
      setRules(updatedRules)
    } else {
      if (rules.includes(rulesReceived)) {
        setRules(rules.filter((permission) => permission !== rulesReceived))
      } else {
        setRules([...rules, rulesReceived])
      }
    }
  }

  /* ----------------- API CALL  */
  const createRole = async () => {
    try {
      const name = roleName !== undefined ? roleName : ''
      const roles = rules.length <= 0 ? [''] : rules
      const description = 'role description'
      const data = {
        name,
        permissions: roles,
        description,
      }
      const response = await Workspaces.addRole(data, workspace._id ?? '')

      onWorkspaceChange(response)
      setTimeout(() => {
        navigate('/settings/workspace', { state: { section: 'workspace' } })
        changeSection('management')
      }, 1000)
    } catch (e) {
      console.error(e)
      showError(T.error.roleAlreadyExists)
    }
  }

  const modifyRole = async (roleId: string) => {
    try {
      const name = roleName !== undefined ? roleName : ''
      const rolesAssigned = rules.length <= 0 ? [''] : rules
      const description = 'undefiened'
      const data = {
        name,
        permissions: rolesAssigned,
        description,
        roleId: roleId,
      }
      const response = await Workspaces.modifyRole(data, workspace._id ?? '', roleId)

      setTimeout(() => {
        onWorkspaceChange(response)
        navigate('/settings/workspace', { state: { section: 'workspace' } })
      }, 1000)
    } catch (e) {
      showError(T.error.genericError)
    }
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    if (newRoleName !== '') {
      setRoleName(newRoleName)
    } else {
      let existRoles
      if (token !== '') {
        const element = workspace.roles?.filter((role) => role._id === token)
        if (element !== undefined && element.length > 0 && Object.keys(element[0]).length !== 0) {
          existRoles = element[0]
          setRoleName(existRoles.name)
          setRules(existRoles.permissions)
          for (const permission of permissions) {
            for (const rule of permission.rules) {
              if (existRoles.permissions.includes(rule.id)) {
                rule.enabled = true
                permission.sectionEnabled = true
              }
            }
          }
        }
        setPermissionsToRender(permissions)
      }
    }
    if (changeName !== '') {
      setTimeout(() => {
        openRenameModal(setRoleName)
      }, 300)
    }
  }, [])

  return (
    <Box animation="fade" gap={24} p={'64px 50px 64px 56px'} height={'100%'} style={{ overflow: 'hidden' }}>
      {/* HEADER */}
      <Box vcenter row justify="space-between">
        <Box row vcenter gap={16}>
          <BackwardIcon
            id={`workspace-row-1`}
            onClick={() => {
              onChangeSection('management')
            }}
            width={16}
            height={16}
          />

          <TitleText>{roleName ?? 'New Role'}</TitleText>

          <EditIcon id={`workspace-row-2`} onClick={() => openRenameModal(setRoleName)} width={24} height={24} />
        </Box>

        <Button
          variant="primary"
          onClick={handleConfirmButton}
          size="small"
          shadow="none"
          flex
          style={{
            border: `1px solid ${AppStore.theme.o.blue}`,
            borderRadius: 14,
            maxWidth: 161,
            height: 40,
            minHeight: 40,
            fontSize: 14,
          }}
        >
          {permissionsToRender.length === 0 ? T.teamMembers.addNewRole : T.settings.confirm}
        </Button>
      </Box>

      {/* CONTENT */}
      <Box width={'100%'} scroll gap={24} style={{ height: 'calc(100% - 64px)' }}>
        {permissionsToRender.length === 0
          ? permissions.map((element, index) => (
              <PermissionsSettingComponent
                listOfPermissions={element}
                sectionIndex={index}
                onPermissionUpdate={onPermissionUpdate}
              />
            ))
          : permissionsToRender.map((element, index) => (
              <PermissionsSettingComponent
                listOfPermissions={element}
                sectionIndex={index}
                onPermissionUpdate={onPermissionUpdate}
              />
            ))}
      </Box>
    </Box>
  )
}

export default UserPermission

const TitleText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;
  color: ${() => AppStore.theme.o.black};
`

const BackwardIcon = styled(Icons.backward)`
  cursor: pointer;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }
`

const EditIcon = styled(Icons.edit)`
  cursor: pointer;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }
`
