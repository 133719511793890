import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { AppStore, showError, T } from 'utils'
import { Box } from './Box'
import { Button } from './Button'
import { Icons } from './Icons'
import { Input } from './Input'
import { Text } from './Text'
import { SearchOutlined } from '@ant-design/icons'
import Workspaces from 'api/Workspaces'
import { Workspace } from 'types'

export interface Roles {
  id: string
  permissions: {
    id: string
    number: number
  }[]
}

interface Props {
  changeSession?: any
  workspace?: any
  users?: any
  rolesNumber?: any
  onWorkspaceChange: (workspace: Workspace) => void
}

export default function RoleGridSection(props: Props) {
  /* ----------------- VARIABLES  */
  const { workspace } = props
  const maxPermissions = [
    {
      id: T.teamMembers.rulesTitle.publication,
      number: 4,
    },
    {
      id: T.teamMembers.rulesTitle.calendar,
      number: 1,
    },
    {
      id: T.teamMembers.rulesTitle.media,
      number: 3,
    },
    {
      id: T.teamMembers.rulesTitle.analytics,
      number: 3,
    },
    {
      id: T.teamMembers.rulesTitle.discover,
      number: 2,
    },
    {
      id: T.teamMembers.rulesTitle.settings,
      number: 3,
    },
  ]
  const sessions = [
    T.teamMembers.tableHeaders.publication,
    T.teamMembers.tableHeaders.calendar,
    T.teamMembers.tableHeaders.media,
    T.teamMembers.rulesTitle.analytics,
    T.teamMembers.rulesTitle.discover,
    T.teamMembers.tableHeaders.settings,
  ]
  const widthPerIndex = {
    0: 82,
    1: 64,
    2: 36,
    3: 55,
    4: 52,
    5: 75,
  }

  /* ----------------- STATE  */
  const [searchText, setSearchText] = useState('')
  const [roles, setRoles] = useState<any>([])
  const [Users, setUsers] = useState<
    Array<{ name: string; email: string; role: string; invitationConfirmed: boolean } | undefined>
  >([])
  const [rolesArray, setRolesArray] = useState<any>(workspace.roles)
  const [filteredRolesArray, setFilteredRolesArray] = useState<any>(workspace.roles)

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- METHODS  */
  const findRole = (roleId: string | undefined, referenceArray?: any) => {
    if (referenceArray !== undefined) {
      if (roleId !== undefined)
        return referenceArray.find((role) => role.roleName.toLowerCase() === roleId.toLowerCase())
    }
    if (roleId !== undefined) return roles.find((role) => role.roleName.toLowerCase() === roleId.toLowerCase())
  }

  /* ----------------- API CALL  */
  const deleteRole = async (roleId: string) => {
    try {
      const response = await Workspaces.deleteRole(workspace._id ?? '', roleId)

      setFilteredRolesArray(filteredRolesArray.filter((role) => role._id !== roleId))
      props.onWorkspaceChange(response)
      setRolesArray(workspace.roles?.filter((role) => role._id !== roleId))
    } catch (error: any) {
      if (error.message.includes('perm')) showError(T.error.roleStillUsed) // se l'utente non ha i permessi mostra errore
    }
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    setUsers(props.users)
  }, [])

  useEffect(() => {
    setRoles(props.rolesNumber)
  }, [props.rolesNumber])

  useEffect(() => {
    setUsers(props.users)
  }, [props.users])

  useEffect(() => {
    setFilteredRolesArray(
      rolesArray.filter((role) => {
        return role.name.toLowerCase().includes(searchText.toLowerCase())
      })
    )
  }, [searchText])

  /* ----------------- COMPONENTS  */
  const NumberComponent = ({ id, roleName, i }) => {
    const idMaxNumber = maxPermissions.filter((permission) => permission.id.toLowerCase() === id.toLowerCase())[0]
      .number

    let backGroundColor = AppStore.theme.o.lightGrey
    let textColor = AppStore.theme.o.darkGrey

    const roleData = roles
      .filter((role) => role.roleName.toLowerCase() === roleName.toLowerCase())[0]
      .rolesNumber.filter((role) => role.name.toLowerCase() === id.toLowerCase())[0].permissionSelected

    if (roleData !== 0) {
      backGroundColor = idMaxNumber === roleData ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey
      textColor = idMaxNumber === roleData ? AppStore.theme.o.blue : AppStore.theme.o.black
    }

    return (
      <Box id={`permission-${id}`} width={widthPerIndex[i]} height={25} center>
        <Box style={{ radius: '50%', backgroundColor: backGroundColor, padding: 4 }} ph={8}>
          <HeaderText style={{ color: textColor, fontSize: 12 }}>
            {roleData}/{idMaxNumber}
          </HeaderText>
        </Box>
      </Box>
    )
  }

  return (
    <Box removeHover hideBar p={'32px'} gap={32} style={{ minHeight: 570, maxHeight: 570 }}>
      {/*  HEADER */}
      <Box vcenter justify="space-between" row>
        <Text style={{ fontSize: 21, fontWeight: 700 }}>{T.teamMembers.manageRole}</Text>

        <Box row gap={24} center>
          <Input
            style={{
              width: 337,
              border: `1px solid ${AppStore.theme.o.darkGrey}`,
              backgroundColor: 'transparent',
              height: 44,
            }}
            placeholder={T.teamMembers.searchRole}
            prefix={<SearchOutlined />}
            onChange={(txt) => setSearchText(txt)}
          />

          <Button
            variant="primary"
            shadow="none"
            style={{ height: 40, minHeight: 40, width: 192 }}
            onClick={() => {
              props.changeSession('permissions?changeName=true' + (workspace._id ? `&id=${workspace._id}` : ''))
              AppStore.closeAllModal()
            }}
          >
            <CreateRoleButtonText>{T.teamMembers.addNewRole}</CreateRoleButtonText>
          </Button>
        </Box>
      </Box>

      {/* GRID CONTAINER */}
      <Box scroll style={{ maxHeight: 440 }} pr={16}>
        {/* GRID HEADER */}
        <Box row vcenter justify="space-between" p={'16px'}>
          <Box width={150} vcenter row>
            <HeaderText>{T.teamMembers.tableHeaders.name}</HeaderText>
          </Box>

          <Box row vcenter justify="space-around" flex style={{ marginLeft: 'auto' }}>
            {sessions.map((session) => (
              <HeaderText>{session}</HeaderText>
            ))}
          </Box>
        </Box>

        <DivisionLine />

        {/* GRID CONTENT */}
        <Box>
          {filteredRolesArray.map((role) => {
            if (role !== undefined)
              return (
                <>
                  <Box relative vcenter row p={'16px'} justify="space-between">
                    <HeaderText style={{ width: 150, textAlign: 'left' }}>{role.name}</HeaderText>

                    <Box row vcenter justify="space-around" flex ml={'auto'}>
                      {findRole(role.name, roles)?.rolesNumber.map((session, i) => {
                        return NumberComponent({ id: session.name, roleName: role.name, i })
                      })}
                    </Box>

                    {/* ACTIONS ICONS */}
                    <Box gap={8} row style={{ position: 'absolute', right: 0 }}>
                      <EditIcon
                        onClick={() => {
                          props.changeSession(`permissions?id=${role._id}`)
                          AppStore.closeAllModal()
                        }}
                      />
                      <SquareTrash
                        onClick={() => {
                          deleteRole(role._id)
                        }}
                      />
                    </Box>
                  </Box>

                  <DivisionLine />
                </>
              )
          })}
        </Box>
      </Box>
    </Box>
  )
}

const DivisionLine = styled.div`
  background-color: ${() => AppStore.theme.o.grey};
  height: 1px;
  width: 100%;
`

const HeaderText = styled.p`
  text-align: center;
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  color: ${() => AppStore.theme.o.black};
`

const CreateRoleButtonText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${() => AppStore.theme.o.blue};
`

const SquareTrash = styled(Icons.squareTrash)`
  width: 20px;
  height: 20px;
  fill: ${() => AppStore.theme.o.darkGrey};
  cursor: pointer;

  &:hover {
    fill: ${() => AppStore.theme.o.red};
  }
`

const EditIcon = styled(Icons.edit)`
  width: 20px;
  height: 20px;
  fill: ${() => AppStore.theme.o.darkGrey};
  cursor: pointer;

  &:hover {
    fill: ${() => AppStore.theme.o.black};
  }
`
