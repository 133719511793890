import { AppStore, T, showError } from 'utils'
import { useEffect, useState } from 'react'
import { useUserStore } from 'stores/UserStore'
import { Button } from 'components'
import BillingService from 'api/service/BillingService'
import styled from 'styled-components'

const BillingLinks = {
  capabilities: 'https://asters.ai',
  faq: 'https://asters.ai',
}

const BillingSection = () => {
  const { plan } = useUserStore((state) => ({ plan: state.user?.subscription?.activePlan }))

  const handleManagePlanClick = async () => {
    try {
      const { data } = await BillingService.recoverManageLink(AppStore.loggedUser._id)
      if (data.url) {
        window.location.href = data.url
      }
    } catch (e) {
      showError(T.billing.settings.error)
    }
  }

  const TrialComponentBar = () => {
    const isPlanTrial = plan?.isTrial
    const freeTrialExpirationDate = plan?.freeTrialExpirationDate
    console.log('isPlanTrial', freeTrialExpirationDate)
    const isFreeTrialValid = freeTrialExpirationDate ? new Date(freeTrialExpirationDate) > new Date() : false
    if (!isPlanTrial || !freeTrialExpirationDate || !isFreeTrialValid) return null
    
    const daysRemaining =  Math.ceil((new Date(freeTrialExpirationDate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))

    return (
      <TrialInfo>
        <TrialText>{T.billing.settings.freeTrial}</TrialText>
        <RemainingDays>{`${T.billing.settings.remainingDays} ${daysRemaining}`}</RemainingDays>
      </TrialInfo>
    )
  }

  return (
    <Container>
      {/** PlanHeader */}
      <PlanHeader>
        <Title>{T.billing.settings.title}</Title>
        <Description>{T.billing.settings.description}</Description>
        <PlanButton onClick={handleManagePlanClick}>{T.billing.settings.manage}</PlanButton>
      </PlanHeader>

      <PlanFooter>
        {/** TrialComponentBar */}
        <TrialComponentBar />

        {/** Links */}
        <Links>
          <Link href={BillingLinks.capabilities} target="_blank">
            {T.billing.settings.checkCapabilities}
          </Link>
          <Link href={BillingLinks.faq} target="_blank">
            {T.billing.settings.faq}
          </Link>
        </Links>
      </PlanFooter>
    </Container>
  )
}

export default BillingSection

// ----------------- STYLES -----------------

const Container = styled.div`
  padding: 64px 80px;
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Title = styled.h1`
  color: ${() => AppStore.theme.o.green};
  font-size: 21px;
  font-weight: 700;
`

const Description = styled.p`
  color: ${() => AppStore.theme.o.textDarkGrey};
  margin-bottom: 20px;
`

const PlanButton = styled(Button)`
  height: 40px !important;
  min-height: 40px !important;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 40px 8px 40px !important;

  background-color: ${() => AppStore.theme.o.primary_container};
  border: 1px solid transparent;
  border-color: ${() => AppStore.theme.o.on_primary_container};
  color: ${() => AppStore.theme.o.on_primary_container};

  &:hover {
    border-color: transparent;
  }
`

const TrialInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  align-items: center;
  padding: 16px 24px;
  border-radius: 14px;
`

const TrialText = styled.span`
  color: ${() => AppStore.theme.o.black};
`

const RemainingDays = styled.span`
  color: ${() => AppStore.theme.o.textDarkGrey};
`

const Links = styled.div`
  margin-top: 20px;
`

const Link = styled.a`
  color: ${() => AppStore.theme.o.textDarkGrey};
  text-decoration: underline;
  margin-right: 20px;

  &:hover {
    text-decoration: underline;
    color: ${() => AppStore.theme.o.black};
  }
`

const PlanHeader = styled.div`
  flex-direction: column;
  justify-content: space-between;
`

const PlanFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
`
