import { SocialType } from 'api/Utils'
import { T } from 'translation/i18n'
import { Media } from 'types'
import { TVariantType } from 'types/PostInterface'
import { capitalizeFirstLetter } from 'utils'
import { socialCheckConfig } from 'utils/apiSocialCheckConfig/SocialCheckConfig'

/**
 * @param media Media con la quale fare la verifica dei requisiti
 * @param provider Social con la quale fare la verifica dei requisiti
 * @returns Un'array di stringhe con tutti i problemi identificati con quel media su quel provider
 */
export const checkMediaValidation = (
  media: Media,
  provider: SocialType,
  type: TVariantType,
  thumbnail: Media | undefined
): string[] => {
  let problems: string[] = []

  // @sal - temporary fix for media without metadata
  if (!media.metadata) return problems
  switch (provider) {
    case 'instagram':
      problems = problems.concat(checkMediaValidationInstagram(media, type, thumbnail))
      break

    case 'facebook':
      problems = problems.concat(checkMediaValidationFacebook(media))
      break

    case 'linkedin':
      problems = problems.concat(checkMediaValidationLinkedin(media))
      break

    case 'x':
      problems = problems.concat(checkMediaValidationX(media))
      break

    case 'youtube':
      problems = problems.concat(checkMediaValidationYoutube(media, thumbnail))
      break

    case 'tiktok':
      problems = problems.concat(checkMediaValidationTiktok(media, thumbnail))
      break

    default:
      break
  }

  return problems
}

/**
 *  Check media validation for Instagram
 * @param media media da controllare
 * @param thumbnail thumbnail da controllare
 * @param type tipo di pubblicazione instagram controllare (STANDARD o STORY)
 * @returns Un'array di stringhe con tutti i problemi identificati con quel media su instagram
 */
export const checkMediaValidationInstagram = (media: Media, type: TVariantType, thumbnail?: Media): string[] => {
  const problems: string[] = []
  const provider = 'instagram'
  const {
    checkValidExtension,
    checkSize,
    checkDimension,
    checkMaxDimension,
    checkRatioBetween,
    checkRatioAccepted,
    checkFps,
    checkDuration,
    checkMaxDuration,
    checkBitrate,
  } = socialCheckConfig

  const {
    // Img
    imgTypes,
    maxImgSize,
    minImgRatio,
    maxImgRatio,
    minVisibleImgRatio,
    maxVisibleImgRatio,

    // Img Thumbnail
    reelThumbImgTypes,
    maxReelThumbImgSize,

    // Video
    videoTypes,
    codecVideo,
    maxVideoBitrate,
    maxVideoWidth,
    maxVideoSize,
    minVideoFps,
    maxVideoFps,
    minVideoDuration,
    maxVideoDuration,

    // Stories
    storiesImgTypes,
    storiesMaxImageSize,
    storiesVideoTypes,
    maxStoriesDuration,
    maxStoriesVideoSize,
  } = socialCheckConfig.instagram

  const mediaExtension = media?.metadata?.extension ?? ''
  switch (type) {
    case 'STANDARD':
      switch (media.metadata.fileType) {
        case 'image':
          //? Controllo tipi di estensione
          if (!checkValidExtension(media?.metadata?.extension, imgTypes)) {
            problems.push(
              `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.image.toLowerCase()} ${
                T.singleWord.on
              } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${imgTypes.join(', ')}.`
            )
          }

          //? Controllo dimensione
          if (!checkSize(media.size, maxImgSize)) {
            problems.push(
              `${T.error.theMaximumSize} ${T.singleWord.image.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
                provider
              ].toLowerCase()} ${T.singleWord.is} ${maxImgSize / 1000000}MB`
            )
          }

          // //? Controllo Min e max width image
          // if (!checkDimension(media.metadata.width, minImgWidth, maxImgWidth)) {
          //   problems.push(
          //     `${T.singleWord.image} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minImgWidth}px ${T.singleWord.and} ${T.singleWord.maximum} ${maxImgWidth}px ${T.singleWord.of} ${T.singleWord.width}`
          //   )
          // }

          //? Controllo ratio image
          if (!checkRatioBetween(media.metadata.width, media.metadata.height, minImgRatio, maxImgRatio)) {
            problems.push(
              `${T.singleWord.image} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVisibleImgRatio} ${T.singleWord.and} ${T.singleWord.maximum} ${maxVisibleImgRatio} ${T.singleWord.aspectRatio}`
            )
          }

          break

        case 'video':
          //? Controllo tipi di estensione
          if (!checkValidExtension(media?.metadata?.extension, videoTypes)) {
            problems.push(
              `${T.error.theAcceptedExtensions} ${T.singleWord.video.toLowerCase()} ${
                T.singleWord.on
              } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${videoTypes.join(', ')}.`
            )
          }

          //? Controllo codec video
          if (!codecVideo.includes(media.metadata.codecVideo)) {
            problems.push(
              `${capitalizeFirstLetter(T.singleWord.codecVideo)} ${T.error.mustBe} ${codecVideo.join(', ')}.`
            )
          }

          //? Controllo bitrate QUANTITA' VIDEO
          if (!checkBitrate(media.metadata.bitrateVideo, 1, maxVideoBitrate)) {
            problems.push(
              `${T.singleWord.video} ${T.error.mustBeWithin}  ${T.singleWord.maximum} ${maxVideoBitrate} ${T.singleWord.bitrateVideo}`
            )
          }

          //? Controllo dimensione video
          if (!checkSize(media.size, maxVideoSize)) {
            problems.push(
              `${T.error.theMaximumSize} ${T.singleWord.for} ${T.singleWord.video.toLowerCase()} ${
                T.singleWord.on
              } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.is} ${maxVideoSize / 1000000}MB`
            )
          }

          //? Controllo fps video
          if (!checkFps(media.metadata.fps, minVideoFps, maxVideoFps)) {
            problems.push(
              `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoFps} ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoFps} ${T.singleWord.fps}`
            )
          }

          //? Controllo dimensioni width video
          if (!checkMaxDimension(media.metadata.width, maxVideoWidth)) {
            problems.push(
              `${T.singleWord.video} ${T.error.mustBeWithin}${maxVideoWidth}px ${T.singleWord.of} ${T.singleWord.width}`
            )
          }

          //? Controllo durata video
          if (!checkDuration(media.metadata.duration, minVideoDuration, maxVideoDuration)) {
            problems.push(
              `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoDuration} ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoDuration} ${T.singleWord.seconds}`
            )
          }

          break
      }

      break

    case 'STORY':
      switch (media.metadata.fileType) {
        case 'image':
          //? Controllo tipi di estensione
          if (!checkValidExtension(media?.metadata?.extension, storiesImgTypes)) {
            problems.push(
              `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.story.toLowerCase()} ${
                T.singleWord.on
              } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${storiesImgTypes.join(', ')}.`
            )
          }

          //? Controllo dimensione stories VIDEO
          if (!checkSize(media.size, storiesMaxImageSize)) {
            problems.push(
              `${T.error.theMaximumSize} ${T.singleWord.for} ${T.singleWord.story.toLowerCase()} ${
                T.singleWord.on
              } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.is} ${storiesMaxImageSize / 1000000}MB`
            )
          }

          break

        case 'video':
          //? Controllo tipi di estensione
          if (!checkValidExtension(media?.metadata?.extension, storiesVideoTypes)) {
            problems.push(
              `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.story.toLowerCase()} ${
                T.singleWord.on
              } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${storiesVideoTypes.join(', ')}.`
            )
          }

          //? Controllo dimensione stories VIDEO
          if (!checkSize(media.size, maxStoriesVideoSize)) {
            problems.push(
              `${T.error.theMaximumSize} ${T.singleWord.for} ${T.singleWord.story.toLowerCase()} ${
                T.singleWord.on
              } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.is} ${maxStoriesVideoSize / 1000000}MB`
            )
          }

          //? Controllo duration stories
          if (!checkMaxDuration(media.metadata.duration, maxStoriesDuration)) {
            problems.push(
              `${T.singleWord.story} ${T.error.mustBeWithin}  ${maxStoriesDuration} ${T.singleWord.seconds}`
            )
          }

          break
      }

      break
  }

  //? Controllo tipi di estensione per thumbnail
  if (thumbnail && !checkValidExtension(thumbnail.metadata.extension, reelThumbImgTypes)) {
    problems.push(
      `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.thumbnail.toLowerCase()} ${
        T.singleWord.on
      } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${reelThumbImgTypes.join(', ')}.`
    )
  }

  //? Controllo dimensione thumbnail
  if (thumbnail && !checkSize(thumbnail.size, maxReelThumbImgSize)) {
    problems.push(
      `${T.error.theMaximumSize} ${T.singleWord.thumbnail.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
        provider
      ].toLowerCase()} ${T.singleWord.is} ${maxReelThumbImgSize / 1000000}MB`
    )
  }

  return problems
}

/**
 * Check media validation for Facebook
 * @param media media da controllare
 * @returns Un'array di stringhe con tutti i problemi identificati con quel media su facebook
 */
export const checkMediaValidationFacebook = (media: Media): string[] => {
  const problems: string[] = []
  const provider = 'facebook'
  const {
    checkValueIsUnder,
    checkValueIsOver,
    checkValueIsEqual,
    checkValidExtension,
    checkSize,
    checkDimension,
    checkRatioBetween,
    checkRatioAccepted,
    checkFps,
    checkDuration,
    checkBitrate,
  } = socialCheckConfig

  const {
    // Img
    imgTypes,
    maxImgSize,

    // Video
    videoTypes,
    minVideoFps,
    minVideoWidth,
    minVideoDuration,
    maxVideoDuration,
    acceptedVideoRatios,
  } = socialCheckConfig.facebook
  const mediaExtension = media?.metadata?.extension ?? ''

  switch (media.metadata.fileType) {
    case 'image':
      //? Controllo tipi di estensione
      if (!checkValidExtension(media.metadata?.extension, imgTypes)) {
        problems.push(
          `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.image.toLowerCase()} ${
            T.singleWord.on
          } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${imgTypes.join(', ')}.`
        )
      }

      //? Controllo dimensione
      if (!checkSize(media.size, maxImgSize)) {
        problems.push(
          `${T.error.theMaximumSize} ${T.singleWord.image.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
            provider
          ].toLowerCase()} ${T.singleWord.is} ${maxImgSize / 1000000}MB`
        )
      }

      // //? Controllo Min e max width image
      // if (!checkDimension(media.metadata.width, minImgWidth, maxImgWidth)) {
      //   problems.push(
      //     `${T.singleWord.image} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minImgWidth}px ${T.singleWord.and} ${T.singleWord.maximum} ${maxImgWidth}px ${T.singleWord.of} ${T.singleWord.width}`
      //   )
      // }

      // //? Controllo ratio image
      // if (!checkRatioBetween(media.metadata.aspectRatio, minImgRatio, maxImgRatio)) {
      //   problems.push(
      //     `${T.singleWord.image} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minImgRatio} ${T.singleWord.and} ${T.singleWord.maximum} ${maxImgRatio}  ${T.singleWord.aspectRatio}`
      //   )
      // }

      break

    case 'video':
      //? Controllo tipi di estensione
      if (!checkValidExtension(media.metadata?.extension, videoTypes)) {
        problems.push(
          `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.video.toLowerCase()} ${
            T.singleWord.on
          } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${videoTypes.join(', ')}.`
        )
      }

      //? Controllo FPS video
      if (!checkValueIsOver(media.metadata.fps, minVideoFps)) {
        problems.push(`${T.singleWord.video} ${T.error.mustBeOver}  ${minVideoFps}  ${T.singleWord.fps}`)
      }

      //? Controllo duration video
      if (!checkDuration(media.metadata.duration, minVideoDuration, maxVideoDuration)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoDuration} ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoDuration} ${T.singleWord.seconds}`
        )
      }

      //? Controllo dimensioni width video
      if (!checkValueIsOver(media.metadata.width, minVideoWidth)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeOver} ${minVideoWidth}px ${T.singleWord.of} ${T.singleWord.width}`
        )
      }

      //? Controllo ratio video
      const ratio = media.metadata?.aspectRatio?.toLocaleLowerCase() === 'n/a' ? '16:9' : media.metadata?.aspectRatio
      if (!checkRatioAccepted(ratio, acceptedVideoRatios)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeEqual} ${acceptedVideoRatios.join('o ')} ${T.singleWord.aspectRatio}`
        )
      }

      break
  }

  return problems
}

/**
 * Check media validation for Linkedin
 * @param media media da controllare
 * @returns Un'array di stringhe con tutti i problemi identificati con quel media su linkedin
 */
export const checkMediaValidationLinkedin = (media: Media): string[] => {
  const problems: string[] = []
  const provider = 'linkedin'
  const {
    checkValueIsUnder,
    checkValueIsOver,
    checkValueIsEqual,
    checkValidExtension,
    checkSize,
    checkDimension,
    checkRatioBetween,
    checkRatioAccepted,
    checkFps,
    checkDuration,
    checkBitrate,
  } = socialCheckConfig

  const {
    // Img
    imgTypes,
    maxImgSize,

    // Video
    maxVideoWidth,
    maxVideoHeight,
    minVideoDuration,
    maxVideoDuration,
    maxVideoBitrate,
    minVideoFps,
    maxVideoFps,
    maxVideoSize,
    allowedVideoExtensions,
  } = socialCheckConfig.linkedin
  const mediaExtension = media?.metadata?.extension ?? ''

  switch (media.metadata.fileType) {
    case 'image':
      //? Controllo tipi di estensione
      if (!checkValidExtension(media.metadata?.extension, imgTypes)) {
        problems.push(
          `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.image.toLowerCase()} ${
            T.singleWord.on
          } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${imgTypes.join(', ')}.`
        )
      }

      //? Controllo dimensione
      if (!checkSize(media.size, maxImgSize)) {
        problems.push(
          `${T.error.theMaximumSize} ${T.singleWord.image.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
            provider
          ].toLowerCase()} ${T.singleWord.is} ${maxImgSize / 1000000}MB`
        )
      }

      // //? Controllo ratio image
      // if (!checkRatioBetween(media.metadata.aspectRatio, minMediaRatio, maxMediaRatio)) {
      //   problems.push(
      //     `${T.singleWord.image} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minMediaRatio} ${T.singleWord.and} ${T.singleWord.maximum} ${maxMediaRatio}  ${T.singleWord.aspectRatio}`
      //   )
      // }

      break

    case 'video':
      // Controllo dimensioni massime video
      if (!checkValueIsUnder(media.size, maxVideoSize)) {
        problems.push(
          `${T.error.theMaximumSize} ${T.singleWord.video.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
            provider
          ].toLowerCase()} ${T.singleWord.is} ${maxVideoSize / 1000000}MB`
        )
      }

      // Controllo estensione video
      if (!checkValidExtension(mediaExtension, allowedVideoExtensions)) {
        problems.push(`${T.singleWord.video} ${T.error.mustBe} mp4`)
      }

      //? Controllo max width video
      if (!checkValueIsUnder(media.metadata.width, maxVideoWidth)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeUnder} ${maxVideoWidth}px ${T.singleWord.of} ${T.singleWord.width}`
        )
      }

      //? Controllo max height video
      if (!checkValueIsUnder(media.metadata.height, maxVideoHeight)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeUnder} ${maxVideoHeight}px ${T.singleWord.of} ${T.singleWord.height}`
        )
      }

      //? Controllo bitrate video
      if (!checkValueIsUnder(media.metadata.bitrateVideo, maxVideoBitrate)) {
        problems.push(`${T.singleWord.video} ${T.error.mustBeUnder} ${maxVideoBitrate} ${T.singleWord.bitrateVideo}`)
      }

      //? Controllo duration video
      if (!checkDuration(media.metadata.duration, minVideoDuration, maxVideoDuration)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoDuration} ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoDuration} ${T.singleWord.seconds}`
        )
      }

      //? Controllo fps video
      if (!checkFps(media.metadata.fps, minVideoFps, maxVideoFps)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoFps} ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoFps} ${T.singleWord.fps}`
        )
      }

      break
  }

  return problems
}

/**
 * Check media validation for X
 * @param media media da controllare
 * @returns Un'array di stringhe con tutti i problemi identificati con quel media su X
 */
export const checkMediaValidationX = (media: Media): string[] => {
  const problems: string[] = []
  const provider = 'x'
  const {
    checkValueIsUnder,
    checkValueIsOver,
    checkValueIsEqual,
    checkValidExtension,
    checkSize,
    checkDimension,
    checkRatioBetween,
    checkRatioAccepted,
    checkFps,
    checkDuration,
    checkBitrate,
  } = socialCheckConfig

  const {
    //Img
    imgTypes,
    maxImgSize,

    //Gif
    maxGifSize,
    maxGifFrame,
    maxGifPixels,

    //Video
    codecVideo,
    codecAudio,
    minVideoWidth,
    maxVideoWidth,
    minVideoHeight,
    maxVideoHeight,
    maxVideoSize,
    minVideoFps,
    maxVideoFps,
    minVideoDuration,
    maxVideoDuration,
    minVideoRatio,
    maxVideoRatio,
    visibleVideoRatioMin,
    visibleVideoRatioMax,
  } = socialCheckConfig.x
  const mediaExtension = media?.metadata?.extension ?? ''

  switch (media.metadata.fileType) {
    case 'image':
      //? Controllo tipi di estensione
      if (!checkValidExtension(media.metadata?.extension, imgTypes)) {
        problems.push(
          `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.image.toLowerCase()} ${
            T.singleWord.on
          } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${imgTypes.join(', ')}.`
        )
      }

      // //? Controllo ratio
      // if (!checkRatioAccepted(media.metadata.aspectRatio, imgRatios)) {
      //   problems.push(`${T.singleWord.image} ${T.error.mustBe} ${imgRatios.join(', ')} ${T.singleWord.aspectRatio}`)
      // }

      //? Controllo dimensione
      if (!checkSize(media.size, maxImgSize)) {
        problems.push(
          `${T.error.theMaximumSize} ${T.singleWord.image.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
            provider
          ].toLowerCase()} ${T.singleWord.is} ${maxImgSize / 1000000}MB`
        )
      }

      break

    // case 'gif':
    //   //? Controllo dimensione
    //   if (!checkSize(media.size, maxGifSize)) {
    //     problems.push(
    //       `${T.error.theMaximumSize} ${T.singleWord.gif.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
    //         provider
    //       ].toLowerCase()} ${T.singleWord.is} ${maxGifSize / 1000000}MB`
    //     )
    //   }

    //   break

    case 'video':
      //? Controllo codec video
      if (!codecVideo.includes(media.metadata.codecVideo)) {
        problems.push(`${capitalizeFirstLetter(T.singleWord.codecVideo)} ${T.error.mustBe} ${codecVideo.join(', ')}.`)
      }

      //? Controllo codec audio
      if (!codecAudio.includes(media.metadata.codecAudio)) {
        problems.push(`${capitalizeFirstLetter(T.singleWord.codecAudio)} ${T.error.mustBe} ${codecAudio.join(', ')}.`)
      }

      //? Controllo dimensioni width video
      if (!checkDimension(media.metadata.width, minVideoWidth, maxVideoWidth)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoWidth}px ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoWidth}px ${T.singleWord.of} ${T.singleWord.width}`
        )
      }

      //? Controllo dimensioni height video
      if (!checkDimension(media.metadata.height, minVideoHeight, maxVideoHeight)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoHeight}px ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoHeight}px ${T.singleWord.of} ${T.singleWord.height}`
        )
      }

      //? Controllo dimensione video
      if (!checkSize(media.size, maxVideoSize)) {
        problems.push(
          `${T.error.theMaximumSize} ${T.singleWord.video.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
            provider
          ].toLowerCase()} ${T.singleWord.is} ${maxVideoSize / 1000000}MB`
        )
      }

      //? Controllo fps video
      if (!checkFps(media.metadata.fps, minVideoFps, maxVideoFps)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoFps} ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoFps} ${T.singleWord.fps}`
        )
      }

      //? Controllo duration video
      if (!checkDuration(media.metadata.duration, minVideoDuration, maxVideoDuration)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoDuration} ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoDuration} ${T.singleWord.seconds}`
        )
      }

      //? Controllo ratio video
      if (!checkRatioBetween(media.metadata.width, media.metadata.height, minVideoRatio, maxVideoRatio)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${visibleVideoRatioMin} ${T.singleWord.and} ${T.singleWord.maximum} ${visibleVideoRatioMax} ${T.singleWord.aspectRatio}`
        )
      }

      break
  }

  return problems
}

/**
 * Check media validation for Youtube
 * @param media media da controllare
 * @returns Un'array di stringhe con tutti i problemi identificati con quel media su youtube
 */
export const checkMediaValidationYoutube = (media: Media, thumbnail?: Media): string[] => {
  const problems: string[] = []
  const provider = 'youtube'
  const {
    checkValueIsUnder,
    checkValueIsOver,
    checkValueIsEqual,
    checkValidExtension,
    checkSize,
    checkDimension,
    checkRatioBetween,
    checkRatioAccepted,
    checkFps,
    checkDuration,
    checkBitrate,
  } = socialCheckConfig

  const {
    // Video thumbnail
    maxThumbnailSize,

    // Video
    videoTypes,
    maxVideoSize,
    maxDurationUnverified,
    maxDurationVerified,
  } = socialCheckConfig.youtube
  const mediaExtension = media?.metadata?.extension ?? ''

  //? Controllo tipi di estensione
  if (!checkValidExtension(media.metadata?.extension, videoTypes)) {
    problems.push(
      `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.video.toLowerCase()} ${
        T.singleWord.on
      } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${videoTypes.join(', ')}.`
    )
  }

  //? Controllo dimensione video
  if (!checkSize(media.size, maxVideoSize)) {
    problems.push(
      `${T.error.theMaximumSize} ${T.singleWord.video.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
        provider
      ].toLowerCase()} ${T.singleWord.is} ${maxVideoSize / 1000000}MB`
    )
  }

  //? Controllo duration video (per ora si usa solo maxDurationVerified)
  if (!checkValueIsUnder(media.metadata.duration, maxDurationVerified)) {
    problems.push(`${T.singleWord.video} ${T.error.mustBeUnder} ${maxDurationVerified} ${T.singleWord.seconds}`)
  }

  //---------- THUMBNAIL
  // //? Controllo tipi di estensione per thumbnail
  // if (thumbnail && !checkValidExtension(thumbnail.metadata.extension, thumbnailTypes)) {
  //   problems.push(
  //     `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.thumbnail.toLowerCase()} ${
  //       T.singleWord.on
  //     } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${thumbnailTypes.join(', ')}.`
  //   )
  // }

  //? Controllo dimensione thumbnail
  if (thumbnail && !checkSize(thumbnail.size, maxThumbnailSize)) {
    problems.push(
      `${T.error.theMaximumSize} ${T.singleWord.thumbnail.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
        provider
      ].toLowerCase()} ${T.singleWord.is} ${maxThumbnailSize / 1000000}MB`
    )
  }

  return problems
}

/**
 * Check media validation for Tiktok
 * @param media media da controllare
 * @returns Un'array di stringhe con tutti i problemi identificati con quel media su tiktok
 */
export const checkMediaValidationTiktok = (media: Media, thumbnail?: Media): string[] => {
  const problems: string[] = []
  const provider = 'tiktok'
  const {
    checkValueIsUnder,
    checkValueIsOver,
    checkValueIsEqual,
    checkValidExtension,
    checkSize,
    checkDimension,
    checkRatioBetween,
    checkRatioAccepted,
    checkFps,
    checkDuration,
    checkBitrate,
  } = socialCheckConfig

  const {
    // Thumbnail video
    thumbnailTypes,
    maxThumbnailSize,
    thumbnailRatios,

    //Video
    minVideoDuration,
    maxVideoDuration,
    minVideoFps,
    maxVideoFps,
  } = socialCheckConfig.tiktok

  switch (media.metadata.fileType) {
    case 'video':
      //? Controllo duration video
      if (!checkDuration(media.metadata.duration, minVideoDuration, maxVideoDuration)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoDuration} ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoDuration} ${T.singleWord.seconds}`
        )
      }

      //? Controllo fps video
      if (!checkFps(media.metadata.fps, minVideoFps, maxVideoFps)) {
        problems.push(
          `${T.singleWord.video} ${T.error.mustBeWithin} ${T.singleWord.minimum} ${minVideoFps} ${T.singleWord.and} ${T.singleWord.maximum} ${maxVideoFps} ${T.singleWord.fps}`
        )
      }
      break
  }

  //---------- THUMBNAIL
  // //? Controllo tipi di estensione per thumbnail
  // if (thumbnail && !checkValidExtension(thumbnail.metadata.extension, thumbnailTypes)) {
  //   problems.push(
  //     `${T.error.theAcceptedExtensions} ${T.singleWord.for} ${T.singleWord.thumbnail.toLowerCase()} ${
  //       T.singleWord.on
  //     } ${T.common.socials[provider].toLowerCase()} ${T.singleWord.are} ${thumbnailTypes.join(', ')}.`
  //   )
  // }

  // //? Controllo dimensione thumbnail
  // if (thumbnail && !checkSize(thumbnail.size, maxThumbnailSize)) {
  //   problems.push(
  //     `${T.error.theMaximumSize} ${T.singleWord.thumbnail.toLowerCase()} ${T.singleWord.on} ${T.common.socials[
  //       provider
  //     ].toLowerCase()} ${T.singleWord.is} ${maxThumbnailSize / 1000000}MB`
  //   )
  // }

  // //? Controllo ratio thumbnail
  // if (thumbnail && !checkRatioAccepted(thumbnail.metadata.aspectRatio, thumbnailRatios)) {
  //   problems.push(
  //     `${T.singleWord.thumbnail} ${T.error.mustBe} ${thumbnailRatios.join(', ')} ${T.singleWord.aspectRatio}`
  //   )
  // }

  return problems
}
