import Medias from 'api/Medias'
import * as AWS from 'aws-sdk'
import * as fs from 'fs'
import * as path from 'path'
import { getInfo } from 'react-mediainfo'
import AppStore from './AppStore'

class AWSUtils {
  private static s3: AWS.S3

  /**
   * Configura aws
   */
  static configureAWS(): void {
    const region = process.env.REACT_APP_AWS_REGION || 'default-region'
    const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID || 'default-access-key-id'
    const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || 'default-secret-access-key'

    AWS.config.update({
      region: region,
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      signatureVersion: 'v4',
    })
    AWSUtils.s3 = new AWS.S3()
  }

  /** Upload diretto
   * @param filePath
   */
  static async uploadFileToS3(
    filePath: string,
    bucketName: string,
    key?: string
  ): Promise<AWS.S3.ManagedUpload.SendData> {
    try {
      const fileContent = fs.readFileSync(filePath)
      const fileName = path.basename(filePath)

      const params: AWS.S3.PutObjectRequest = {
        Bucket: process.env.REACT_APP_AWS_BUCKET || 'default-bucket',
        Key: key || fileName,
        Body: fileContent,
      }

      const data = await AWSUtils.s3.upload(params).promise()
      return data
    } catch (err) {
      console.error('Error uploading file:', err)
      throw err
    }
  }

  /**
   * Upload a pezzi
   * @param formData
   * @param key
   * @returns
   */
  static async uploadFileInChunks(
    formData: FormData,
    key: string,
    onUploadProgress?: (progressEvent: any) => void
  ): Promise<any> {
    try {
      const file = formData.get('file') as File
      const filePath = formData.get('path') as string
      if (!file) {
        throw new Error('File not found in FormData')
      }

      // Obtain metadata for the file
      const metadata = await this.getFileMetadata(file)

      // Check if the file is eligible for upload
      const data = await Medias.verifyUploadLimits({ size: file.size, workspaceId: AppStore.getWorkspaceId() })
      if (data.problem) {
        throw new Error('File exceeds the upload limits')
      }
      // rimuove caratteri speciali dal nome del file tipo accenti
      const fileName = (key || file.name)
        .replace(/ /g, '_')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')

      const uploadParams: AWS.S3.CreateMultipartUploadRequest = {
        Bucket: process.env.REACT_APP_AWS_BUCKET || 'default-bucket',
        Key: fileName,
      }

      const multipartUpload = await AWSUtils.s3.createMultipartUpload(uploadParams).promise()
      const chunkSize = 5 * 1024 * 1024 // 5MB per chunk
      const chunks = Math.ceil(file.size / chunkSize)
      let partNumber = 0
      const parts: AWS.S3.CompletedPart[] = []

      for (let i = 0; i < chunks; i++) {
        const start = i * chunkSize
        const end = Math.min(start + chunkSize, file.size)
        const blob = file.slice(start, end)
        const arrayBuffer = await blob.arrayBuffer()
        const buffer = Buffer.from(arrayBuffer)

        const partParams: AWS.S3.UploadPartRequest = {
          Bucket: process.env.REACT_APP_AWS_BUCKET || 'default-bucket',
          Key: fileName,
          PartNumber: ++partNumber,
          UploadId: multipartUpload.UploadId!,
          Body: buffer,
        }

        const uploadedPart = await AWSUtils.s3.uploadPart(partParams).promise()
        onUploadProgress && onUploadProgress({ loaded: i, total: chunks })

        parts.push({
          ETag: uploadedPart.ETag,
          PartNumber: partNumber,
        })
      }

      const completeParams: AWS.S3.CompleteMultipartUploadRequest = {
        Bucket: process.env.REACT_APP_AWS_BUCKET || 'default-bucket',
        Key: fileName,
        MultipartUpload: {
          Parts: parts,
        },
        UploadId: multipartUpload.UploadId!,
      }

      await AWSUtils.s3.completeMultipartUpload(completeParams).promise()

      const presignedUrl = AWSUtils.s3.getSignedUrl('getObject', {
        Bucket: process.env.REACT_APP_AWS_BUCKET || 'default-bucket',
        Key: key,
        Expires: 3600, // URL valido per 1 ora
      })

      const uploadData: IMediaUploadData = {
        path: filePath,
        fileName,
        size: file.size,
        mimetype: this.getMimeType(file),
        visible_name: file.name,
      }

      // If all is ok notify the database
      this.notifyDatabase({
        name: fileName,
        key: key,
        workspace: AppStore.getWorkspaceId(),
        metadata,
        uploadData,
      })

      return { presignedUrl, filename: key }
    } catch (err) {
      throw err
    }
  }

  // ----------------------- METADATA -----------------------

  /**
   * Recupera i metadati di un'immagine
   * @param file
   * @returns
   */
  static async getImageMetadata(file: File): Promise<MediaMetadata> {
    return new Promise((resolve, reject) => {
      const url = URL.createObjectURL(file)
      const img = new Image()

      img.onload = () => {
        const aspectRatio = this.getAspectRatio(img.width, img.height)
        resolve({
          width: img.width,
          height: img.height,
          codecVideo: 'N/A',
          bitrateVideo: -1,
          codecAudio: 'N/A',
          bitrateAudio: -1,
          duration: -1,
          fps: -1,
          aspectRatio,
          colorSpace: 'unknown', // This value is not easily accessible via this method
          extension: file.name.split('.').pop() || '',
          fileType: 'image',
          mimeType: this.getMimeType(file),
        })
        URL.revokeObjectURL(img.src)
      }

      img.onerror = () => {
        reject(new Error('Failed to load image'))
      }

      img.src = url
    })
  }

  /**
   * Recupera i metadati di un file multimediale
   * @param file
   * @returns
   */
  static async getMediaMetadata(file: File): Promise<MediaMetadata> {
    try {
      const result = await getInfo(file)
      if (result && result.media.track) {
        const videoTrack = result.media.track.find((track: any) => track['@type'] === 'Video') || {}
        const audioTrack = result.media.track.find((track: any) => track['@type'] === 'Audio') || {}

        return {
          width: parseInt(videoTrack.Width) || -1,
          height: parseInt(videoTrack.Height) || -1,
          codecVideo: videoTrack.CodecID || 'unknown',
          bitrateVideo: parseInt(videoTrack.BitRate) || -1,
          codecAudio: audioTrack.CodecID || 'unknown',
          bitrateAudio: parseInt(audioTrack.BitRate) || -1,
          duration: parseFloat(videoTrack.Duration) || parseFloat(audioTrack.Duration) || -1,
          fps: parseFloat(videoTrack.FrameRate) || -1,
          aspectRatio: videoTrack.DisplayAspectRatio || 'unknown',
          colorSpace: videoTrack.ColorSpace || 'unknown',
          extension: file.name.split('.').pop() || '',
          mimeType: this.getMimeType(file),
          fileType: videoTrack['@type'] === 'Video' ? 'video' : audioTrack['@type'] === 'Audio' ? 'audio' : 'unknown',
        }
      }
    } catch (error) {
      console.error('Error retrieving metadata:', error)
      throw error
    }

    return {
      width: -1,
      height: -1,
      codecVideo: 'unknown',
      bitrateVideo: -1,
      codecAudio: 'unknown',
      bitrateAudio: -1,
      duration: -1,
      fps: -1,
      aspectRatio: 'unknown',
      colorSpace: 'unknown',
      mimeType: '',
      extension: file.name.split('.').pop() || '',
      fileType: 'unknown',
    }
  }

  /**
   * Recover metadata from a file
   * @param file
   * @returns
   */
  static async getFileMetadata(file: File): Promise<MediaMetadata> {
    const extension = file.name.split('.').pop()?.toLowerCase() || ''
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff'].includes(extension)) {
      return this.getImageMetadata(file)
    } else {
      return this.getMediaMetadata(file)
    }
  }

  /**
   * Restituisce il rapporto di aspetto di un'immagine
   * @param width
   * @param height
   * @returns
   */
  static getAspectRatio(width: number, height: number): string {
    const decimalRatio = width / height
    return this.mapAspectRatio(decimalRatio)
  }

  static getMimeType(file: File): string {
    const extension = file.name.split('.').pop()?.toLowerCase() || ''
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg'
      case 'png':
        return 'image/png'
      case 'gif':
        return 'image/gif'
      case 'bmp':
        return 'image/bmp'
      case 'webp':
        return 'image/webp'
      case 'tiff':
        return 'image/tiff'
      case 'mp4':
        return 'video/mp4'
      case 'webm':
        return 'video/webm'
      case 'ogg':
        return 'video/ogg'
      case 'mp3':
        return 'audio/mp3'
      case 'wav':
        return 'audio/wav'
      case 'mov':
        return 'video/mov'
      case 'flac':
        return 'audio/flac'
      default:
        return 'application/octet-stream'
    }
  }

  /**
   * Normalize the aspect ratio to a common format
   * @param decimalRatio
   * @returns
   */
  static mapAspectRatio(decimalRatio: number): string {
    // Definisce le soglie per i rapporti di aspetto comuni
    const ratios: { [key: string]: { min: number; max: number } } = {
      '2:3': { min: 0.66, max: 0.67 },
      '4:5': { min: 0.8, max: 0.81 },
      '1:1': { min: 0.95, max: 1.05 },
      '3:2': { min: 1.5, max: 1.52 },
      '4:3': { min: 1.33, max: 1.34 },
      '5:4': { min: 1.25, max: 1.26 },
      '14:9': { min: 1.55, max: 1.56 },
      '16:9': { min: 1.77, max: 1.79 },
      '1.85:1': { min: 1.85, max: 1.86 },
      '1.91:1': { min: 1.91, max: 1.92 },
      '2:1': { min: 2, max: 2.02 },
      '18:9': { min: 2, max: 2 },
      '19.5:9': { min: 2.16, max: 2.18 },
      '21:9': { min: 2.33, max: 2.39 },
      '2.35:1': { min: 2.35, max: 2.36 },
      '2.39:1': { min: 2.39, max: 2.4 },
      '2.40:1': { min: 2.4, max: 2.41 },
      '2.55:1': { min: 2.55, max: 2.56 },
      '2.76:1': { min: 2.76, max: 2.77 },
      '3:1': { min: 3, max: 3.02 },
      '1.43:1': { min: 1.43, max: 1.44 },
    }

    const decimalRatioFixed = Number(decimalRatio.toFixed(2))

    // Trovare il rapporto di aspetto corrispondente
    for (const [aspectRatio, range] of Object.entries(ratios)) {
      if (decimalRatioFixed >= range.min && decimalRatioFixed <= range.max) {
        return aspectRatio
      }
    }

    // Se nessun rapporto corrispondente è trovato, restituire il valore calcolato originale
    return decimalRatio.toFixed(2)
  }

  // --------------------- REMOTE UPLOAD ---------------------

  /**
   * Upload a file to Asters DB
   * @param mediaRequest
   * @returns
   */
  static notifyDatabase = async (mediaRequest: MediaRequest): Promise<any> => {
    return Medias.addToStorage(mediaRequest)
  }
}

export interface IMediaUploadData {
  path: string
  fileName: string
  size: number
  mimetype: string
  visible_name: string
  buffer?: Buffer
}

export interface IMediaUploadResponse {
  presignedUrl: string
  filename: string
  originalname: string
}

//// ------- new ------
export interface MediaMetadata {
  width: number
  height: number
  codecVideo: string
  bitrateVideo: number
  codecAudio: string
  bitrateAudio: number
  duration: number
  fps: number
  aspectRatio: string
  colorSpace: string
  extension: string
  mimeType: string
  fileType: 'image' | 'video' | 'audio' | 'unknown'
}

export interface MediaRequest {
  name: string
  key: string
  workspace: string
  uploadData: IMediaUploadData
  metadata: MediaMetadata
}

export default AWSUtils
