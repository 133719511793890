import { api, responseErrorCheck } from './api'
import AstersBaseResource from './AstersBaseResource'
import { AppStore } from 'utils'
import { IMetric } from 'types/AnalyticsInferfaces'

// <-------------------------INFERFACE------------------------->

export default class Metrics extends AstersBaseResource {
  static endpoint = '/metrics'

  static async getMetrics() {
    return api
      .get<any>(`${this.endpoint}/getMetricList/${AppStore.workspace._id}/${AppStore.getLanguageValue() ?? 'it'}`)
      .then(responseErrorCheck)
      .catch(responseErrorCheck)
  }
}
