import styled from '@emotion/styled'
import opsAnimation from 'assets/plan-expired.json'
import { Box } from 'components'
import { UpgradeModal } from 'components/Modal/Billing/UpgradeModal'
import { SubscriptionChecker } from 'components/SubscriptionChecker'
import { useEffect } from 'react'
import { useUserStore } from 'stores/UserStore'
import { User } from 'types'
import MediaUtils from 'utils/MediaUtils'

const CourtesyPage = () => {
  const user = useUserStore((state) => state.user)

  const forceRedirect = () => {
    if (user) {
      const isExpired =
        (user as User).subscription &&
        (user as User).subscription.activePlan.expirationDate &&
        new Date((user as User).subscription.activePlan.expirationDate) < new Date()
      if (!isExpired) window.location.href = '/'
    }
  }

  const courtesyOptions = {
    loop: true,
    animationData: opsAnimation,
    position: -99999999,
  }

  useEffect(() => {
    // forceRedirect()
  }, [])

  const CourtesyInterface = () => {
    return (
      <SubscriptionContainer>
        <UpgradeModal logout />
      </SubscriptionContainer>
    )
  }

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        backgroundSize: 'contain',
      }}
    >
      <CourtesyInterface />
    </Box>
  )
}

export default CourtesyPage

const SubscriptionContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center !important;
  align-items: center !important;
  justify-content: left;
  background: url(${MediaUtils.backgrounUrl}) no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
`
