import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import { Box, LoginCard, LoginTabs, SignupCard, ZoomedContainer } from 'components'
import SignUpAnimation from 'components/Animations/SignUpAnimation'
import gsap from 'gsap'
import { useEffect, useRef, useState } from 'react'
import { T, __ } from 'translation/i18n'

import { AppStore } from 'utils'
import {
  TRACKEREVENTS,
  getBrowserProperties,
  getDeviceProperties,
  getLocationProperties,
  getRefUrlProperties,
  getUtmProperties,
} from 'utils/eventsTracker'
import MediaUtils from 'utils/MediaUtils'

const backgroundImages = [MediaUtils.backgrounUrl]

const elaborateSelectedView = () => {
  const params = new URLSearchParams(window.location.search)
  if (params.has('email')) return 0
  return AppStore.parampsOption?.whereToRedirect === 'signUp' || !AppStore.parampsOption?.whereToRedirect ? 1 : 0
}

export const LoginPage = (props) => {
  const { loggedUser, onLoginCallback } = props

  const [activeTab, setActiveTab] = useState(elaborateSelectedView())
  const [activeBackground, setActiveBackground] = useState(Math.floor(Math.random() * backgroundImages.length))
  const cardContainer = useRef(null)

  const changeLang = async () => {
    await localStorage.setItem('firstTime', 'false')
    const lang = navigator.language
    if (lang.split('-')[0] === 'it') {
      await localStorage.setItem('defaultLang', 'it')
    } else {
      await localStorage.setItem('defaultLang', 'en')
      history.go(0)
    }
  }

  const trackSoftwareLaunched = async () => {
    const softwareLaunchTrackProperties = {
      ...getUtmProperties(),
      ...getRefUrlProperties(),
      ...getBrowserProperties(),
      ...getDeviceProperties(),
      ...(await getLocationProperties()),
    }

    Tracker.trackEvent(TRACKEREVENTS.SOFTWARE_LAUNCHED, softwareLaunchTrackProperties)
  }
  //change background image every 5 seconds
  const changeBackground = () => {
    setTimeout(() => {
      setActiveBackground((prev) => (prev + 1) % backgroundImages.length)
      changeBackground()
    }, 10000)
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    // force switch to signup if the user is not logged
    params.has('email') ? switchToLogin() : switchToSignup()
  }, [])

  useEffect(() => {
    // force theme in light mode
    // if (window.location.pathname === '/') AppStore.forceTheme(false) // set theme in dark mode

    // Verify browser language
    //const browserLanguage = navigator.language
    //console.log('Browser language:', browserLanguage);

    // Verify browser language
    //const browserLanguage = navigator.language
    //console.log('Browser language:', browserLanguage);

    // force switch to signup if the user is not logged
    // switchToSignup()
    backgroundImages.forEach((backgroundImage) => {
      const img = new Image()
      img.src = backgroundImage
    })
    const firstTime = localStorage.getItem('firstTime') ?? 'true'
    if (firstTime === 'true') changeLang()
    changeBackground()

    trackSoftwareLaunched()
  }, [])

  const switchToLogin = () => {
    if (activeTab === 1) {
      setActiveTab(0)
      SignUpAnimation.swapFromSignUpToLogin('FullLogInCard', 'FullSingUpCard')
    }
    // update height of the cardcontainer
    gsap.to(cardContainer.current, { height: 600, duration: 0.5 })
  }

  const switchToSignup = () => {
    if (activeTab === 0) {
      setActiveTab(1)
      SignUpAnimation.swapFromLoginToSignUp('FullLogInCard', 'FullSingUpCard')
    }
    gsap.to(cardContainer.current, { height: 700, duration: 0.5 })
  }

  const tabs = [
    {
      text: __(T.misc.signin),
      onClick: () => switchToLogin(),
    },
    {
      text: __(T.misc.signup),
      onClick: () => switchToSignup(),
    },
  ]

  const requestHeightChange = async (passwordRequested) => {
    const newHeight = passwordRequested ? 300 : 600
    gsap.to(cardContainer.current, { height: newHeight, duration: 0.5, minHeight: newHeight })
  }

  return (
    <LoginPageContainer background={backgroundImages[activeBackground]}>
      <ZoomedContainer>
        {/* ------------------------ LOGIN / REGISTER TABS */}
        <LoginTabs active={activeTab} options={tabs} />

        {/* ------------------------ LOGIN / REGISTER CARDS */}
        <CardContainer ref={cardContainer}>
          <Box>
            <Box
              id={'FullLogInCard'}
              flex
              style={{ position: 'absolute', minWidth: 420, top: 0, x: activeTab === 0 ? 0 : -500 }}
            >
              <LoginCard
                onLoginCallback={onLoginCallback}
                onChangeSelected={() => setActiveTab(1)}
                onRecoverRequested={requestHeightChange}
              />
            </Box>

            <Box
              id={'FullSingUpCard'}
              flex
              style={{ position: 'absolute', minWidth: 420, top: 0, x: activeTab === 0 ? +500 : 0 }}
            >
              <SignupCard onLoginCallback={onLoginCallback} onChangeSelected={() => setActiveTab(1)} />
            </Box>
          </Box>
        </CardContainer>
      </ZoomedContainer>
    </LoginPageContainer>
  )
}

const LoginPageContainer = styled.div<{ background }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center !important;
  align-items: center !important;
  justify-content: left;
  background: url(${(props) => props.background}) no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  margin-top: -1px;
  min-height: 600px;
  background-color: ${() => AppStore.theme.o.surface};
  border-radius: 0px 0px 14px 14px;
  min-width: 500px;
  position: relative;
  overflow: hidden;
  /* background-color: red; */
`
