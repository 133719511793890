import { ChangeEvent, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Box, Icons, Button } from 'components'
import { AppStore, T, showInfo, showSuccess, showWarning } from 'utils'
import { IArticle, IArticleShort, TBoardIsPrivate, TButtonType } from '../aiDiscoverInterface'
import { createBoardOBJ, getBoardsList, postCreateBoard, postSaveArticleInBoard } from '../commonFunction'
import asters_placeholder from 'assets/asters_placeholder.png'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ___________________ INTERFACE ___________________ */
interface Props {
  isOpen?: boolean // se è true allora la card board si apre
  article?: IArticle // articolo
  isOnArticleModal?: boolean // se è true allora la card board non è dentro <PreviewCard> ma è dentro <ArticlePreviewModals>
  onSelectBoardOnModal?: any // funzione per selezionare una board se si è in
  isOnlyCreate?: boolean // se è true allora la card board non mostra le board ma solo la possibilità di crearne una
  onBoardCreationComplete?: () => void
}

const BoardCard = ({
  isOpen,
  article,
  onBoardCreationComplete,
  isOnArticleModal = false,
  onSelectBoardOnModal,
  isOnlyCreate,
}: Props) => {
  /* ____________________ STATE ____________________ */

  const [boardIdListSelected, setBoardIdListSelected] = useState<string[]>([]) // lista di id delle board selezionate
  const [boardButton, setBoardButton] = useState<TButtonType>('new-board')
  const [boardIsPrivate, setBoardIsPrivate] = useState<TBoardIsPrivate>(true)
  const [inputValue, setInputValue] = useState<string>('')
  const [inputUniqueValue, setInputUniqueValue] = useState<number>(Math.floor(Math.random() * 1000))
  const [onforceUpdateThis, setOnForceUpdateThis] = useState<boolean>(false)
  const [canCreatePrivateBoard, setCanCreatePrivateBoard] = useState<boolean>(true)

  /* ____________________ REF ____________________ */
  const inputRef = useRef<HTMLInputElement>(null)

  /* ____________________ ZUSTAND  ____________________ */
  // boardList e selezione board sono nel context per poter essere condivisi con altri componenti (AiDiscoverPage è dove sono stati creati)
  const { onForceUpdate, onSelectBoard, activeDropdown } = useDiscoverStore((state) => ({
    onForceUpdate: state.onForceUpdate,
    onSelectBoard: state.onSelectBoard,
    activeDropdown: state.activeDropdown,
  }))
  /* ____________________ VARIABLES ____________________ */
  const boardList = AppStore.discoverUserBoardList

  const updateForce = () => {
    onForceUpdate && onForceUpdate()
    setOnForceUpdateThis((prev) => !prev)
  }

  /* ____________________ METHODS ____________________ */
  const onChangeBoardButton = (value: TButtonType = 'new-board') => {
    if (isOnlyCreate) {
      setBoardButton('create')
      return
    }
    setBoardButton(value)
  }

  const onChangeBoardType = (value: TBoardIsPrivate = true) => {
    setBoardIsPrivate(value)
  }
  const onChangeInputValue = (event: ChangeEvent<HTMLInputElement>) => {
    // se ci sono due spazi di fila li sostituisco con uno solo
    setInputValue(event.target.value.replace('  ', ' '))
  }

  /**
   * ## Gestisce il click sul button della card
   *
   * ### Se il button è "new-board" allora va alla creazione di una nuova board
   *
   * ### Se il button è "save" allora salva l'articolo in una o più board
   *
   * ### Se il button è "create" allora crea una nuova board
   * se l'input è vuoto non fa niente
   *
   * @returns void
   */
  const onClickButtonCard = async (e) => {
    e.preventDefault()
    const check = await AppStore.checkPermission('manage_discover')
    if (!check) return showInfo(`${T.error.noPermissionTo} ${T.error.manageDiscover}`)

    if (boardButton === 'new-board') {
      // vai alla creazione di una nuova board
      onChangeBoardButton('create')
      return
    }

    if (boardButton === 'save') {
      // Salva articolo in una o più board
      if (boardIdListSelected.length === 0) return

      const savedArticleCheck: boolean[] = []

      for (const bId of boardIdListSelected) {
        const savedArticle = await postSaveArticleInBoard(bId, article)

        savedArticleCheck.push(savedArticle)
      }

      if (savedArticleCheck.every((el) => el === false)) {
        return resetCardBoard()
      }
      showSuccess(T.AiDiscoverPage.boardCardSucces.saveArticle)

      resetCardBoard()
      getBoardsList(updateForce)

      return
    }

    if (boardButton === 'create') {
      // Crea una nuova board
      if (inputValue.trim().length === 0) return

      const checkBoard = boardList.find(
        (b) => b.boardTitle.toLowerCase() === inputValue.toLowerCase() && b.private === boardIsPrivate
      )

      if (checkBoard !== undefined) {
        showWarning(
          `${T.AiDiscoverPage.boardCardWarning.createDoubleBoard} ${
            boardIsPrivate ? T.AiDiscoverPage.word.private : T.AiDiscoverPage.word.shared
          }`
        )

        return
      }

      let articleShort: IArticleShort | any = undefined
      if (article) {
        articleShort = {
          articleId: article.uri,
          image: article.image ?? asters_placeholder,
          title: article.title,
          language: article.lang,
          commentsNumber: 0,
        }
      }

      const newBoard = createBoardOBJ(inputValue, boardIsPrivate, [articleShort])

      await postCreateBoard(newBoard, article)

      getBoardsList(updateForce)

      showSuccess(T.AiDiscoverPage.boardCardSucces.createBoard)
      resetCardBoard()
      if (onBoardCreationComplete) {
        onBoardCreationComplete()
      }
    }
  }

  const onClickSelectBoard = (boardId: string) => {
    if (boardList) onSelectBoard(boardIdListSelected, setBoardIdListSelected, boardId)
    if (isOnArticleModal) onSelectBoardOnModal(boardIdListSelected, setBoardIdListSelected, boardId)
  }

  /**
   * ## Resetta la board card
   * @returns void
   */
  const resetCardBoard = () => {
    onChangeBoardButton()
    onChangeBoardType()
    setCanCreatePrivateBoard(true)
    setInputValue('')
    setBoardIdListSelected([])
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (inputValue.trim() === '') return

      onClickButtonCard(event)
    }
  }
  /* ____________________ API CALL ____________________ */

  // useEffect che si attiva ad ogni cambiamento di variabili
  useEffect(() => {
    if (!isOpen) {
      // reset card board
      resetCardBoard()
      return
    }

    setTimeout(() => inputRef.current?.focus(), 300)
  }, [isOpen])

  // useEffect che si attiva ad ogni cambiamento
  useEffect(() => {
    if (activeDropdown === 'shared') setBoardIsPrivate(false)

    if (isOnlyCreate) {
      setBoardButton('create')
      return
    }
    // Cambio il button type in "save" se ho almeno un elemento selezionato
    if (boardIdListSelected.length > 0) onChangeBoardButton('save')
    // se non ho nessun elemento selezionato e ho cliccato per andare su "CREATE" o "NEW BOARD" faccio un return per non far fare niente
    else if (boardButton === 'create' || boardButton === 'new-board') return
    //  se le condizioni prima sono false allora cambio il button type in "new-board" ( reset )
    else onChangeBoardButton()
  })

  useEffect(() => {
    const workspaceUserId = AppStore.workspace.user
    const loggedUserId = AppStore.loggedUser._id

    if (workspaceUserId !== loggedUserId) {
      setCanCreatePrivateBoard(false)
      onChangeBoardType(false)
    }
  }, [])

  return (
    <PreviewBoardContainer
      className={`preview-board-container ${isOpen ? 'open' : 'not-open'} ${
        isOnArticleModal ? 'is-on-article' : 'is-on-Card'
      }`}
    >
      {/* ______________ CARD _______________ */}
      <CardBoard>
        {/* ____________________ TITLE ____________________ */}
        <BoardTitle>
          {boardButton !== 'create' && T.AiDiscoverPage.boardCard.saveOnBoard}
          {boardButton === 'create' && (
            <>
              {!isOnlyCreate && <Icons.arrowLeft style={{ marginRight: 8 }} onClick={() => onChangeBoardButton()} />}

              {T.AiDiscoverPage.boardCard.createBoard}
            </>
          )}
        </BoardTitle>

        {/* ____________ CREATE NEW BOARD PRIVATE OR PUBLIC CONTAINER  ____________ */}
        {boardButton === 'create' && (
          <PrivateOrPublicContainer>
            {/* ______________ TEXT PRIVATE / SHARED _______________ */}
            <ToggleText
              className={`${boardIsPrivate ? 'active' : 'not-active'} ${!canCreatePrivateBoard ? 'not-possible' : ''}`}
              onClick={() => {
                if (!canCreatePrivateBoard) {
                  showWarning(T.AiDiscoverPage.boardCardWarning.cantCreateBoardPrivate)
                  return
                }
                onChangeBoardType(true)
              }}
            >
              {T.AiDiscoverPage.word.private}
            </ToggleText>

            <ToggleText
              className={`${!boardIsPrivate ? 'active' : 'not-active'}`}
              onClick={() => onChangeBoardType(false)}
            >
              {T.AiDiscoverPage.word.shared}
            </ToggleText>
          </PrivateOrPublicContainer>
        )}

        {/* ____________________ INPUT CONTAINER ____________________ */}
        <InputContainer>
          <input
            ref={inputRef}
            id={`search-board-${inputUniqueValue}`}
            style={boardButton === 'create' ? { paddingLeft: 16 } : {}}
            value={inputValue}
            onChange={onChangeInputValue}
            onKeyDown={(e) => handleKeyDown(e)}
            type="text"
          />

          {boardButton !== 'create' && (
            <InputSearchIcon>
              <Icons.search />
            </InputSearchIcon>
          )}

          <label
            htmlFor={`search-board-${inputUniqueValue}`}
            className={`${inputValue.split('').length > 0 ? 'hide' : ''}`}
          >
            {boardButton !== 'create' ? (
              <span>{T.AiDiscoverPage.word.search}</span>
            ) : (
              T.AiDiscoverPage.boardCard.newBoard
            )}
          </label>
        </InputContainer>

        {/* ____________________ SCROLL-BOARD CONTAINER ____________________ */}
        {!isOnlyCreate && (
          <ScrollBoardContainer className={`${boardButton === 'create' ? 'hidden' : ''}`}>
            {/* __________________ BOARD __________________ */}

            {boardList &&
              (boardList.filter((b) => b.boardTitle.toLowerCase().includes(inputValue.toLowerCase())).length > 0 ? (
                boardList
                  .filter((b) => b.boardTitle.toLowerCase().includes(inputValue.toLowerCase()))
                  .reverse()
                  .map((el) => {
                    const articleInBoard = el.articles
                    const articleIsJustSaved = articleInBoard.filter((art) => art.articleId === article?.uri).length > 0

                    return (
                      <ScrollItemContainer
                        className={`item ${articleIsJustSaved ? 'art-in-board' : ''}`}
                        onClick={() => {
                          if (articleIsJustSaved) return
                          onClickSelectBoard(el._id ?? '')
                        }}
                      >
                        <ScrollImgTextContainer>
                          <ScrollImg className={`${boardIdListSelected.includes(el._id ?? '') ? 'selected' : ''}`}>
                            {el.articles.length > 0 ? (
                              <img
                                src={el.articles[0].image ?? asters_placeholder}
                                alt={el.boardTitle ?? ''}
                                style={{ backgroundColor: `${AppStore.theme.o.lightGrey}` }}
                              />
                            ) : (
                              <img
                                src={asters_placeholder}
                                alt={'Asters placeholder'}
                                style={{ backgroundColor: `${AppStore.theme.o.lightGrey}` }}
                              />
                            )}
                            {boardIdListSelected.includes(el._id ?? '') && <Icons.simpleCheck />}
                          </ScrollImg>

                          <ScrollText>{el.boardTitle}</ScrollText>
                        </ScrollImgTextContainer>

                        <ScrollIcon>
                          {el.private && <Icons.privateBoard className="private" width={32} height={32} />}
                          {!el.private && <Icons.publicBoard className="shared" width={32} height={32} />}
                        </ScrollIcon>
                      </ScrollItemContainer>
                    )
                  })
              ) : (
                <AdviseNewBoard>
                  {boardIdListSelected.length === 0 && (
                    <div>
                      {T.AiDiscoverPage.boardCard.adviseNewBoard} <br />"
                      <span>
                        {inputValue.trim().length > 0 ? inputValue : T.AiDiscoverPage.boardCard.yourFistBoard}
                      </span>
                      " &nbsp;⬇️
                    </div>
                  )}
                  {boardIdListSelected.length > 0 && T.AiDiscoverPage.boardCard.adviseSearchBoardNotFind}
                </AdviseNewBoard>
              ))}
          </ScrollBoardContainer>
        )}

        {/* ____________________ BUTTON CONTAINER ____________________ */}
        <ButtonContainer>
          <Button
            height={'fit-content'}
            width={287}
            size="small"
            shadow="none"
            variant={
              boardButton === 'new-board'
                ? 'neutral'
                : boardButton === 'save'
                ? 'secondary'
                : inputValue.trim().length > 0
                ? 'primary'
                : 'neutral'
            }
            onClick={onClickButtonCard}
          >
            {boardButton === 'new-board' && T.AiDiscoverPage.boardCard.newBoard}
            {boardButton === 'save' && T.AiDiscoverPage.boardCard.saveOnBoard}
            {boardButton === 'create' && T.AiDiscoverPage.boardCard.createBoard}
          </Button>
        </ButtonContainer>
      </CardBoard>
    </PreviewBoardContainer>
  )
}

export default BoardCard

const PreviewBoardContainer = styled(Box)`
  &.preview-board-container {
    background-color: ${() => `${AppStore.theme.o.surface}`};
    width: 351px;
    height: 423px;
    min-height: 423px;
    max-height: 423px;
    position: absolute;
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: ${() => `1px solid transparent`};
    border-radius: 24px;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    &.open {
      left: 0;
    }

    &.is-on-article {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 100;
    }
  }
`

const CardBoard = styled(Box)`
  width: 100%;
  min-height: 423px;
  max-height: 423px;
  position: relative;
  padding: 16px 24px;
  display: flex;
  gap: 16px;
  border-radius: 24px;
`

const BoardTitle = styled(Box)`
  color: ${() => `${AppStore.theme.o.black}`};
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.04px;
  letter-spacing: 0.18px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  border-radius: 0px;

  svg {
    fill: ${() => `${AppStore.theme.o.darkGrey}`};
    height: 14px;
    width: 14px;
    cursor: pointer;
  }

  &.hidden {
    display: none;
  }
`

const PrivateOrPublicContainer = styled(Box)`
  width: 112px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
`

const ToggleText = styled(Box)`
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  padding: 4px 8px;
  border-radius: 8px;
  color: ${() => `${AppStore.theme.o.darkGrey}`};
  background-color: ${() => `${AppStore.theme.o.lightestGrey}`};

  &.active {
    background-color: ${() => `${AppStore.theme.o.lightGreen}`};
    color: ${() => `${AppStore.theme.o.green}`};
  }

  &.not-possible {
    cursor: not-allowed;
  }
`

const InputContainer = styled(Box)`
  border-radius: 0px;
  position: relative;

  input {
    width: 100%;
    background-color: ${() => `${AppStore.theme.o.lightestGrey}`};
    color: ${() => `${AppStore.theme.o.black}`};
    font-size: 14px;
    font-weight: 700;
    padding: 12px 16px 12px 46px;
    border: 1px solid ${() => `${AppStore.theme.o.grey}`};
    border-radius: 14px;

    &:hover,
    &:focus {
      background-color: 1px solid ${() => `${AppStore.theme.o.grey}`};
      border: 1px solid transparent;
    }
  }

  label {
    color: ${() => `${AppStore.theme.o.darkGrey}`};
    font-family: 'aktiv-grotesk';
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 12px 16px;

    span {
      padding-left: 31px;
    }
  }
`
const InputSearchIcon = styled(Box)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${() => `${AppStore.theme.o.darkGrey}`};
  }
`
const ScrollBoardContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 0px;

  height: 100%;
  padding-bottom: 188px;
  overflow: auto;

  &.hidden {
    opacity: 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`

const ScrollItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  max-height: 100%;
  overflow: auto;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${() => `${AppStore.theme.o.grey}`};
    background: transparent;
  }

  &.art-in-board {
    background-color: ${() => `${AppStore.theme.o.lightGrey}`};
    cursor: auto;
  }
`

const ScrollImgTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  overflow: hidden;
  border-radius: 0px;
`

const ScrollImg = styled(Box)`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &.selected {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #00000080;
      z-index: 1;
    }
  }

  img {
    height: 48px;
    width: 48px;
    object-fit: cover;
    border-radius: 8px !important;
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    z-index: 3;
  }
  img {
    height: 48px;
    width: 48px;
    object-fit: cover;
    border-radius: 8px !important;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const ScrollText = styled(Box)`
  color: ${() => `${AppStore.theme.o.black}`};
  flex-grow: 1;
  display: block;
  max-width: 180px;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.16px;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const ScrollIcon = styled(Box)`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  overflow: hidden;

  svg {
    &.private {
      background: ${() => `${AppStore.theme.o.lightestGrey}`};

      rect {
        stroke: ${() => `${AppStore.theme.o.grey}`};
      }

      path {
        fill: ${() => `${AppStore.theme.o.black}`};
      }
    }

    &.shared {
      rect {
        fill: ${() => `${AppStore.theme.o.lightBlue}`};
      }
      path {
        fill: ${() => `${AppStore.theme.o.blue}`} !important;
      }
    }
  }
`

const AdviseNewBoard = styled(Box)`
  width: 100%;
  height: fit-content;
  color: ${() => `${AppStore.theme.o.black}`};
  background-color: ${() => `${AppStore.theme.o.lightestGrey}`};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
  text-align: -webkit-center;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 14px;

  span {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
  }
`

const ButtonContainer = styled(Box)`
  background: linear-gradient(
    180deg,
    ${() => `${AppStore.theme.o.surface}`}00 0%,
    ${() => `${AppStore.theme.o.surface}`} 35%,
    ${() => `${AppStore.theme.o.surface}`} 100%
  );
  width: 100%;
  height: 94px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`
