import ManageNotifications from 'components/ManageNotifications'
import { WorkspaceModal } from 'components/Modal/WorkspaceModal'
import { SpinnerAstersLogo } from 'components/UI'
import UserPermission from 'components/UserPermission'
import WorkspaceApiKeys from 'components/WorkspaceApiKeys/WorkspaceApiKeys'
import WorkspaceComponent from 'components/WorkspaceComponent'
import WorkspaceManagement from 'components/WorkspaceManagement'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SocialAccount, Workspace } from 'types'
import { AppStore, showError, T } from 'utils'
import { isVisible, RemoteConfigValues } from 'utils/CheckVisibleElement'

const urlExtractor = (url: string) => {
  const urlArray = url.split('/')
  const path = urlArray[3] ?? 'workspace'
  return path
}

const WorkspaceSection = () => {
  // ----------------------------- ROUTER
  const navigate = useNavigate()
  const location = useLocation()

  // ----------------------------- STATE
  const [socialAccounts, setSocialAccounts] = useState<SocialAccount[]>(AppStore.socialAccountList)
  const [search, setSearch] = useState<string>('')
  const [workspaces, setWorkspaces] = useState<Workspace[]>([
    ...AppStore.loggedUser.workspaces,
    ...AppStore.loggedUser.collabWorkspace.map((w) => w.workspace),
  ])
  const [section, setSection] = useState<string>(urlExtractor(location.pathname))
  const [workspaceToManage, setWorkspaceToManage] = useState<Workspace>(AppStore.workspace)
  const [index, setIndex] = useState<number>(0)
  const [forceUpdate, setForceUpdate] = useState(false)

  const [loading, setLoading] = useState<boolean>(true)

  const onWorkspaceConfirmation = () => {
    const allWorkspaces = [
      ...AppStore.loggedUser.workspaces,
      ...AppStore.loggedUser.collabWorkspace.map((w) => w.workspace),
    ]
    setWorkspaces(allWorkspaces)

    AppStore.workspacesSetNotifications(allWorkspaces)
  }

  // ----------------------------- MODALS
  const openWorkspaceModal = (workspace?: Workspace) =>
    AppStore.openModal({
      id: 'workspace-modal',
      body: (
        <WorkspaceModal
          workspace={workspace}
          onConfirm={onWorkspaceConfirmation}
          onWorkspaceDelete={() => setSection('workspace')}
        />
      ),
      style: { minWidth: 720, maxWidth: 720, minHeight: 334, maxHeight: 334 },
    })

  // ----------------------------- METHODS
  const handleForceUpdate = () => {
    setForceUpdate((prev) => !prev)
  }

  const refreshSocialAccounts = async () => {
    try {
      const allSocials = await AppStore.refreshSocialaccounts()
      setSocialAccounts(allSocials as SocialAccount[])
    } catch (error: any) {
      showError(error?.message ?? T.error.error)
    }
  }

  const onSearchChange = (text: string) => {
    setSearch(text)
  }

  const onWorkspaceChange = (workspace) => {
    const allWorkspaces = [
      ...AppStore.loggedUser.workspaces,
      ...AppStore.loggedUser.collabWorkspace.map((w) => w.workspace),
    ].find((w) => w._id === workspace._id)

    const updatedWorkspace = { ...workspace, image: allWorkspaces?.image } as Workspace
    setWorkspaces((prev) =>
      prev.map((w, i) => (w._id?.toString() === updatedWorkspace._id?.toString() ? updatedWorkspace : w))
    )
    setWorkspaceToManage(workspace)
  }

  const onChangeSection = (newSection: string, newWorkspaceToManage?: Workspace) => {
    if (newWorkspaceToManage) {
      const findIndex = workspaces.findIndex((w) => w._id === newWorkspaceToManage._id)
      setWorkspaceToManage(newWorkspaceToManage)
      setIndex(findIndex)
    }

    if (newSection.includes('permissions')) {
      navigate(`/settings/workspace/${newSection}`, { state: { section: newSection } })
      setSection('permissions')
      return
    }

    if (newSection === 'workspace') {
      navigate(`/settings/workspace`, { state: { section: newSection } })
      // refresh()
    } else {
      navigate(`/settings/workspace/${newSection}${newWorkspaceToManage ? '/' + newWorkspaceToManage._id : ''}`, {
        state: { section: newSection },
      })
    }
    setSection(newSection)
  }

  const firstMount = async () => {
    await refreshSocialAccounts()
    setSection(urlExtractor(location.pathname))
    setLoading(false)
  }

  // ----------------------------- USEEFFECT
  useEffect(() => {
    setLoading(true)

    // Aggiunto setTimeout perché l'aggiornamento proprietà expired backend richiede un pò di tempo
    setTimeout(() => {
      firstMount()
    }, 1000)
  }, [forceUpdate])

  useEffect(() => {
    const sec = urlExtractor(location.pathname)
    setSection(sec)

    // if (sec && sec.includes('permissions')) setSection('permissions')
    // else if (sec && sec.includes('management')) setSection('management')
    // else if (sec && sec.includes('api-keys')) setSection('api-keys')
    // else if (sec && sec.includes('notification')) setSection('notification')
    // else setSection('workspace')
  }, [location, window.location])

  return (
    <>
      {loading && <SpinnerAstersLogo dimension={100} />}

      {!loading && (
        <>
          {section === 'workspace' && (
            <WorkspaceComponent
              handleForceUpdate={handleForceUpdate}
              searchText={search}
              openWorkSpaceModal={openWorkspaceModal}
              workspaces={workspaces}
              socialAccounts={socialAccounts}
              searchChange={onSearchChange}
              changeSection={onChangeSection}
              onWorkspaceChange={onWorkspaceChange}
            />
          )}

          {section === 'management' && (
            <WorkspaceManagement
              changeSection={onChangeSection}
              workspace={workspaceToManage}
              onWorkspaceChange={onWorkspaceChange}
              openWorkspaceModal={openWorkspaceModal}
            />
          )}

          {section === 'api-keys' && <WorkspaceApiKeys changeSection={onChangeSection} allWorkspaces={workspaces} />}

          {section === 'permissions' && (
            <UserPermission
              changeSection={onChangeSection}
              workspace={workspaces[index]}
              onWorkspaceChange={onWorkspaceChange}
            />
          )}

          {isVisible(RemoteConfigValues.NotificationDisabled) && section === 'notification' && (
            <ManageNotifications
              changeSection={onChangeSection}
              workspace={workspaceToManage}
              onWorkspaceChange={onWorkspaceChange}
            />
          )}
        </>
      )}
    </>
  )
}

export default WorkspaceSection
